import useSwr from 'swr';
import { DefaultApi } from '@@/generated/openapi';
import { CustomSwrHook } from '@/interfaces/swr';
import { normalizeKey } from '@/helpers/swr';

export const useApp = ({
  key,
  args,
  config,
  pause,
}: CustomSwrHook<DefaultApi['getApplication']> = {}) => {
  const client = useApiClient();
  const params = useParams();

  const normalizedKey = normalizeKey({
    key,
    pause,
    defaultKey: ['app', params?.app],
  });
  const normalizedArgs = args ?? [params?.app ?? ''];

  return useSwr(
    normalizedKey,
    () => client.getApplication(...normalizedArgs),
    config,
  );
};
