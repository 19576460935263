import Markdown from '@/components/Markdown/Markdown';
import classes from './ReadOnlyInput.module.scss';
import classnames from 'classnames';
import { InputProps } from '@/interfaces/fields';

interface ReadOnlyInputProps extends InputProps {
  value: string;
}

const ReadOnlyInput = ({
  name,
  value,
  text,
  hiddenlabel = false,
}: ReadOnlyInputProps) => {
  return (
    <div>
      {!hiddenlabel && <div className={classes.label}>{text.label}</div>}
      <div
        className={classnames(classes.value, { [classes.placeholder]: !value })}
        data-testid={name}
      >
        {value ?? '---'}
      </div>
      {text.hint && <Markdown>{text.hint}</Markdown>}
    </div>
  );
};

export default ReadOnlyInput;
