import { getPersonalTeamName } from '@/helpers/teams';
import { TeamV1Dto } from '@@/generated/mtts/openapi';

export const useTeamOptions = () => {
  const user = useUser();
  const { data } = useTeams({
    pause: !user,
    args: [0, 100, user.id],
    showOnlyOwned: true,
  });
  const { content: teams } = data || {};

  const teamOptions = useMemo(() => {
    if (!teams || !user) {
      return [];
    }
    const newTeamName = getPersonalTeamName(user?.email || '');

    const teamOptions = teams.map((team: TeamV1Dto) => ({
      value: team.id,
      id: team.id,
      label: team.id,
      secondary: team.id === newTeamName ? 'Your personal team' : '',
    }));

    const personalTeamExists = teamOptions.some(
      (team) => team.value === newTeamName,
    );

    const newAppTeam = {
      value: newTeamName,
      id: 'new-team',
      label: newTeamName,
      secondary: `Your personal team`,
    };

    return !personalTeamExists
      ? [newAppTeam, ...teamOptions]
      : teamOptions.sort((a, b) =>
          a.value === newTeamName ? -1 : b.value === newTeamName ? 1 : 0,
        );
  }, [teams, user]);
  return teamOptions;
};
