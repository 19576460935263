import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AccountV1Dto } from '@@/generated/openapi';
import Input from '@/components/Input/Input';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { ChooseTopics } from '../ChooseTopics/ChooseTopics';
import { FormInputGroup } from '@/components/FormInputGroup/FormInputGroup';

const schema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string(),
});

const defaultAccount: Partial<AccountV1Dto> = {
  name: '',
  applicationCode: '',
  environmentClass: 'NONPROD',
  description: '',
  produceTopics: [
    {
      resourceType: 'TOPIC',
      applicationCode: 'keepmdptest',
      resourceId: 'keepmdptest.topic.sandbox.testtopic',
    },
  ],
  consumeTopics: [
    {
      resourceType: 'TOPIC',
      applicationCode: 'keepmdptest',
      resourceId: 'keepmdptest.topic.sandbox.testtopic',
    },
  ],
};

type AccountFormProps = {
  initialData?: Partial<AccountV1Dto>;
  onSubmit: (data: AccountV1Dto) => Promise<void>;
  onCancel?: () => void;
  isLoading?: boolean;
};

export const AccountForm = ({
  initialData = defaultAccount,
  onSubmit,
  onCancel,
  isLoading,
}: AccountFormProps) => {
  const { app } = useParams();
  const form = useForm<AccountV1Dto>({
    // @ts-expect-error schema is not properly typed
    resolver: yupResolver(schema),
    defaultValues: {
      applicationCode: app ?? '',
      ...initialData,
      produceTopics: initialData.produceTopics ?? [],
      consumeTopics: initialData.consumeTopics ?? [],
    },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    await onSubmit(data);
  });

  return (
    <FormWrapper form={form} schema={schema}>
      {/* <pre>{JSON.stringify(form.getValues(), null, 2)}</pre> */}
      <form onSubmit={handleSubmit}>
        <FormInputGroup>
          <FormInputGroup width="normal">
            <Input
              name="name"
              text={{
                hint: 'Enter the account name',
                label: 'Account Name',
                placeholder: 'Enter account name',
              }}
              disabled={isLoading}
              data-testid="account-name"
            />

            <Input
              name="description"
              text={{
                hint: 'Enter a description for the account',
                label: 'Description',
                placeholder: 'Enter description',
              }}
              data-testid="description"
              disabled={isLoading}
            />
          </FormInputGroup>

          <FormInputGroup appearance="primary" bordered>
            <ChooseTopics />
          </FormInputGroup>

          <div className="form-actions">
            {onCancel && (
              <McButton
                type="button"
                variant="outlined"
                appearance="primary"
                onClick={onCancel}
                loading={isLoading}
              >
                Cancel
              </McButton>
            )}
            <McButton loading={isLoading} type="submit">
              Save Account
            </McButton>
          </div>
        </FormInputGroup>
      </form>
    </FormWrapper>
  );
};
