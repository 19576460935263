import { FormProvider, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import ValidationSchemaProvider from '@/providers/ValidationSchemaProvider';
import { ReactNode } from 'react';
import { FC } from 'react';

interface FormWrapperProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  // form: FormProviderProps;
  schema: yup.Schema;
  children: ReactNode;
}

const FormWrapper: FC<FormWrapperProps> = ({
  form,
  schema,
  children,
}: FormWrapperProps) => {
  return (
    <ValidationSchemaProvider schema={schema}>
      <FormProvider {...form}>{children}</FormProvider>
    </ValidationSchemaProvider>
  );
};

export default FormWrapper;
