import { McSelectOption, SelectProps } from '@/interfaces/fields';
import classes from './ReadOnlySelect.module.scss';
import Markdown from '@/components/Markdown/Markdown';
import classnames from 'classnames';

interface ReadOnlySelectProps extends SelectProps {
  value: McSelectOption[];
}

const ReadOnlySelect = ({ name, value, text }: ReadOnlySelectProps) => {
  return (
    <div>
      <div className={classes.label}>{text.label}</div>
      <div
        className={classnames(classes.value, {
          [classes.placeholder]: !value[0],
        })}
        data-testid={name}
      >
        {value[0]?.label ?? '---'}
      </div>
      {text.hint && <Markdown>{text.hint}</Markdown>}
    </div>
  );
};

export default ReadOnlySelect;
