/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataQualityDto } from './DataQualityDto';
import {
    DataQualityDtoFromJSON,
    DataQualityDtoFromJSONTyped,
    DataQualityDtoToJSON,
} from './DataQualityDto';

/**
 * 
 * @export
 * @interface DataQualityListDto
 */
export interface DataQualityListDto {
    /**
     * 
     * @type {number}
     * @memberof DataQualityListDto
     */
    totalRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityListDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityListDto
     */
    currentPage?: number;
    /**
     * 
     * @type {Array<DataQualityDto>}
     * @memberof DataQualityListDto
     */
    dataQuality?: Array<DataQualityDto>;
}

/**
 * Check if a given object implements the DataQualityListDto interface.
 */
export function instanceOfDataQualityListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataQualityListDtoFromJSON(json: any): DataQualityListDto {
    return DataQualityListDtoFromJSONTyped(json, false);
}

export function DataQualityListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataQualityListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'dataQuality': !exists(json, 'dataQuality') ? undefined : ((json['dataQuality'] as Array<any>).map(DataQualityDtoFromJSON)),
    };
}

export function DataQualityListDtoToJSON(value?: DataQualityListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalRecords': value.totalRecords,
        'totalPages': value.totalPages,
        'currentPage': value.currentPage,
        'dataQuality': value.dataQuality === undefined ? undefined : ((value.dataQuality as Array<any>).map(DataQualityDtoToJSON)),
    };
}

