import classes from './SkipContent.module.scss';

interface SkipContentProps {
  id?: string;
  message?: string;
}

const SkipContent = ({
  id = 'main',
  message = 'Skip to main content',
}: SkipContentProps) => {
  return (
    <a href={`#${id}`} className={classes.skip}>
      {message}
    </a>
  );
};

export default SkipContent;
