import ReactMarkdown from 'react-markdown';
import classes from './Markdown.module.scss';
import classnames from 'classnames';

export enum MarkdownFit {
  Small = 'Small',
  Medium = 'Medium',
}

interface MarkdownProps {
  children: string;
  fit?: MarkdownFit;
  className?: string;
  isHint?: boolean;
}
const Markdown = ({
  children,
  fit = MarkdownFit.Small,
  className = '',
  isHint = false,
}: MarkdownProps) => {
  return (
    <ReactMarkdown
      className={classnames(classes.markdown, {
        [className]: className,
        [classes.small]: fit === MarkdownFit.Small,
        [classes.medium]: fit === MarkdownFit.Medium,
        [classes.hint]: isHint,
      })}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
