import { GrowthBook } from '@growthbook/growthbook-react';

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: window.envVars?.VITE_GROWTHBOOK_CLIENT_KEY ?? '',
  enableDevMode: true,
  subscribeToChanges: true,
  attributes: {
    url: window.location.href,
    user_id: '',
    team_refs: [],
  },
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

export default growthbook;
