import { FC } from 'react';
import { createContext, ReactNode } from 'react';
import * as yup from 'yup';

export const ValidationSchemaContext = createContext<yup.Schema>(yup.object());

interface ValidationSchemaProviderProps {
  children: ReactNode;
  schema: yup.Schema;
}

const ValidationSchemaProvider: FC<ValidationSchemaProviderProps> = ({
  children,
  schema,
}: ValidationSchemaProviderProps) => {
  return (
    <ValidationSchemaContext.Provider value={schema}>
      {children}
    </ValidationSchemaContext.Provider>
  );
};

export default ValidationSchemaProvider;
