import { CustomSwrHook } from '@/interfaces/swr';
import { DefaultApi, TeamPageDto } from '@@/generated/mtts/openapi';
import useSwr from 'swr';
import { normalizeKey } from '@/helpers/swr';
import { UserProviderInterface } from '@/providers/User';

const transformTeams = (
  teams: TeamPageDto,
  user: UserProviderInterface,
  showOnlyOwned = false,
) => {
  const result = {
    ...teams,
    content: teams.content
      ?.map((team) => ({
        ...team,
        userIds: [...team.ownerIds, ...(team.memberIds || [])],
      }))
      ?.filter(
        (team) => !showOnlyOwned || Array.from(team.ownerIds).includes(user.id),
      ),
  };
  return result;
};

interface TeamsHookOptions extends CustomSwrHook<DefaultApi['getTeams']> {
  showOnlyOwned?: boolean;
}

export const useTeams = ({
  key,
  args,
  config,
  pause,
  page = 0,
  size = 100,
  showOnlyOwned = false,
}: TeamsHookOptions = {}) => {
  const client = useMttsApiClient();
  const params = useParams();
  const env = useNormalizedEnv();
  const user = useUser();
  const normalizedKey = normalizeKey({
    key,
    pause,
    defaultKey: ['teams', params?.app, env, params?.service, page, size],
  });
  const normalizedArgs = args ?? [page, size, user.id];

  return useSwr(
    normalizedKey,
    () =>
      client
        .getTeams(...normalizedArgs)
        .then((teams) => transformTeams(teams, user, showOnlyOwned)),
    config,
  );
};
