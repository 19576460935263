import { ReactNode } from 'react';
import classnames from 'classnames';
import classes from './PageBody.module.scss';
interface PageBodyProps {
  children: ReactNode;
  className?: string;
  padding?: boolean;
  title?: string;
}

const PageBody = ({ children, className, padding, title }: PageBodyProps) => {
  return (
    <div
      id={'page-body'}
      className={classnames(className, padding && classes.padding)}
    >
      {title && <h2 className={classes.pageBodyTitle}>{title}</h2>}
      {children}
    </div>
  );
};

export default PageBody;
