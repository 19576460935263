/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Technical Team Service (MTTS)
 * Maersk Technical Team Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationIdDto
 */
export interface ApplicationIdDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationIdDto
     */
    id: string;
}

/**
 * Check if a given object implements the ApplicationIdDto interface.
 */
export function instanceOfApplicationIdDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ApplicationIdDtoFromJSON(json: any): ApplicationIdDto {
    return ApplicationIdDtoFromJSONTyped(json, false);
}

export function ApplicationIdDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationIdDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function ApplicationIdDtoToJSON(value?: ApplicationIdDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}

