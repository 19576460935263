import classes from './PageHeader.module.scss';
import { ReactNode } from 'react';
import SkipContent from '@/components/SkipContent/SkipContent';
import classnames from 'classnames';

interface SharedPageHeaderProps {
  children: ReactNode;
  className?: string;
}

interface PageHeaderProps extends SharedPageHeaderProps {
  subtle?: boolean;
}

export const PageType = ({ children }: SharedPageHeaderProps) => {
  return <div className={classes.type}>{children}</div>;
};

export const PageName = ({ children, className }: PageHeaderProps) => {
  return (
    <h1 data-testid={'page-name'} className={`${classes.name} ${className}`}>
      {children}
    </h1>
  );
};

export const PageDetails = ({ children }: SharedPageHeaderProps) => {
  return <div className={classes.info}>{children}</div>;
};

export const PageDetailsRow = ({
  children,
  className,
}: SharedPageHeaderProps) => {
  return (
    <div className={classnames(classes.infoRow, className)}>{children}</div>
  );
};

export const PageDetailsColumn = ({
  children,
  className = '',
}: SharedPageHeaderProps) => {
  return (
    <div className={classnames(classes.infoCol, className)}>{children}</div>
  );
};

export const PageDetailsHead = ({ children }: SharedPageHeaderProps) => {
  return <div className={classes.infoHead}>{children}</div>;
};

export const PageDetailsBody = ({
  children,
  className,
}: SharedPageHeaderProps) => {
  return (
    <div className={classnames(classes.infoBody, className)}>{children}</div>
  );
};

export const PageHeader = ({
  children,
  subtle = false,
  className,
}: PageHeaderProps) => {
  return (
    <div
      className={`${classes.header} ${subtle ? classes.subtle : ''} ${
        className ?? ''
      }`}
    >
      <SkipContent id={'page-body'} message={'Skip page details'} />
      {children}
    </div>
  );
};

export const PageActions = ({ children }: SharedPageHeaderProps) => {
  return <div className={classes.actions}>{children}</div>;
};

PageHeader.Type = PageType;
PageHeader.Name = PageName;
PageHeader.Details = PageDetails;
PageHeader.Actions = PageActions;

export default PageHeader;
