import Button, { ButtonAppearance } from '@/components/Button/Button';
import UserBadge, { UserBadgeFit } from '@/components/UserBadge/UserBadge';
import classes from './AccountDropdown.module.css';
import { useMsal } from '@azure/msal-react';
import useUser from '@/hooks/useUser';
import { McListItem } from '@maersk-global/mds-react-wrapper/components-core/mc-list-item';
import { McMenu } from '@maersk-global/mds-react-wrapper/components-core/mc-menu';
import { McList } from '@maersk-global/mds-react-wrapper/components-core/mc-list';
import { header } from '@text';
import { getTextMessage } from '@/helpers/text';

const AccountDropdown = () => {
  const { theme, toggleTheme } = useTheme();
  const { instance } = useMsal();
  const user = useUser();

  const handleLogout = () => {
    void instance.logoutRedirect();
  };

  return (
    <McMenu position={'bottom-right'}>
      <Button
        slot={'trigger'}
        appearance={ButtonAppearance.Plain}
        className={classes.menuActivator}
      >
        <UserBadge identity={user} fit={UserBadgeFit.Small} />
      </Button>
      <McList>
        <McListItem
          label={getTextMessage(header.theme, {
            theme: theme === 'light' ? 'Dark' : 'Light',
          })}
          icon={theme === 'light' ? 'moon' : 'sun'}
          onClick={toggleTheme}
        />
        <McListItem
          label={header.signOut}
          icon={'door-arrow-right'}
          onClick={handleLogout}
        />
      </McList>
    </McMenu>
  );
};

export default AccountDropdown;
