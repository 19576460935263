/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Technical Team Service (MTTS)
 * Maersk Technical Team Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PipelineStatusV1Dto
 */
export interface PipelineStatusV1Dto {
    /**
     * 
     * @type {string}
     * @memberof PipelineStatusV1Dto
     */
    readonly pipelineId: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineStatusV1Dto
     */
    readonly runId: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineStatusV1Dto
     */
    readonly pipelineType: PipelineStatusV1DtoPipelineTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineStatusV1Dto
     */
    readonly status: PipelineStatusV1DtoStatusEnum;
    /**
     * useful for communicating reason for failed pipeline
     * @type {string}
     * @memberof PipelineStatusV1Dto
     */
    statusMessage?: string;
    /**
     * 
     * @type {Date}
     * @memberof PipelineStatusV1Dto
     */
    readonly creationTimestamp: Date;
}


/**
 * @export
 */
export const PipelineStatusV1DtoPipelineTypeEnum = {
    TeamCreation: 'TEAM_CREATION',
    TeamDeletion: 'TEAM_DELETION',
    TeamUpdate: 'TEAM_UPDATE',
    TeamResync: 'TEAM_RESYNC',
    ApplicationCreation: 'APPLICATION_CREATION'
} as const;
export type PipelineStatusV1DtoPipelineTypeEnum = typeof PipelineStatusV1DtoPipelineTypeEnum[keyof typeof PipelineStatusV1DtoPipelineTypeEnum];

/**
 * @export
 */
export const PipelineStatusV1DtoStatusEnum = {
    Pending: 'PENDING',
    Running: 'RUNNING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;
export type PipelineStatusV1DtoStatusEnum = typeof PipelineStatusV1DtoStatusEnum[keyof typeof PipelineStatusV1DtoStatusEnum];


/**
 * Check if a given object implements the PipelineStatusV1Dto interface.
 */
export function instanceOfPipelineStatusV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pipelineId" in value;
    isInstance = isInstance && "runId" in value;
    isInstance = isInstance && "pipelineType" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "creationTimestamp" in value;

    return isInstance;
}

export function PipelineStatusV1DtoFromJSON(json: any): PipelineStatusV1Dto {
    return PipelineStatusV1DtoFromJSONTyped(json, false);
}

export function PipelineStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pipelineId': json['pipelineId'],
        'runId': json['runId'],
        'pipelineType': json['pipelineType'],
        'status': json['status'],
        'statusMessage': !exists(json, 'statusMessage') ? undefined : json['statusMessage'],
        'creationTimestamp': (new Date(json['creationTimestamp'])),
    };
}

export function PipelineStatusV1DtoToJSON(value?: PipelineStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusMessage': value.statusMessage,
    };
}

