import { Environments } from '@/interfaces/environments';

const VALID_ENVS: string[] = Object.values(Environments);

export const useNormalizedEnv = () => {
  const params = useParams();

  return useMemo(() => {
    if (params.env && VALID_ENVS.includes(params.env)) {
      return params.env;
    }

    return VALID_ENVS[0];
  }, [params.env]);
};
