/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Technical Team Service (MTTS)
 * Maersk Technical Team Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityV1Dto
 */
export interface IdentityV1Dto {
    /**
     * 
     * @type {string}
     * @memberof IdentityV1Dto
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityV1Dto
     */
    readonly displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityV1Dto
     */
    readonly email?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityV1Dto
     */
    readonly provider: IdentityV1DtoProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityV1Dto
     */
    readonly type: IdentityV1DtoTypeEnum;
}


/**
 * @export
 */
export const IdentityV1DtoProviderEnum = {
    AzureAd: 'AZURE_AD'
} as const;
export type IdentityV1DtoProviderEnum = typeof IdentityV1DtoProviderEnum[keyof typeof IdentityV1DtoProviderEnum];

/**
 * @export
 */
export const IdentityV1DtoTypeEnum = {
    User: 'USER',
    ServiceAccount: 'SERVICE_ACCOUNT'
} as const;
export type IdentityV1DtoTypeEnum = typeof IdentityV1DtoTypeEnum[keyof typeof IdentityV1DtoTypeEnum];


/**
 * Check if a given object implements the IdentityV1Dto interface.
 */
export function instanceOfIdentityV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function IdentityV1DtoFromJSON(json: any): IdentityV1Dto {
    return IdentityV1DtoFromJSONTyped(json, false);
}

export function IdentityV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'provider': json['provider'],
        'type': json['type'],
    };
}

export function IdentityV1DtoToJSON(value?: IdentityV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

