import useError from '@/hooks/useError';
import useSWR, {
  Fetcher,
  Key,
  Middleware,
  SWRConfiguration,
  SWRResponse,
} from 'swr';
import { CustomSwrConfig } from '@/interfaces/swr';
import useNotFound from '@/hooks/useNotFound';

const serializeKey = (key: Key) =>
  Array.isArray(key) ? JSON.stringify(key) : key;

export const swrErrorHandler: Middleware =
  (useSwr) => (key, fetcher, config: CustomSwrConfig) => {
    const { setError } = useError();

    const serializedKey = serializeKey(key);
    const swr = useSwr(key, fetcher, config);

    // Remove current error if key matches
    // useEffect(() => {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   if (swr.data && (error as any)?.key === serializedKey) {
    //     setError();
    //   }
    // }, [error, swr.data, serializedKey, setError]);

    useEffect(() => {
      const error = swr.error;
      if (error) {
        if (error.response) {
          if (!(error.response.status === 404 && config.suppress404)) {
            const clone = error.response.clone();
            clone
              .json()
              .then((body: object) => {
                setError({
                  ...body,
                  key: serializedKey,
                });
              })
              .catch(() => {
                error.response.text().then((text: string) => {
                  setError({
                    status: error.response.status,
                    type: error.response.type,
                    url: error.response.url,
                    body: text,
                    statusText: error.response.statusText,
                    key: serializedKey,
                  });
                });
              });
          }
        } else {
          setError({ ...error, key: serializedKey });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swr.error, setError, serializedKey]);

    return swr;
  };

export const swrNotFoundHandler: Middleware =
  (useSwr) => (key, fetcher, config) => {
    const [, setNotFound] = useNotFound();

    const swr = useSwr(key, fetcher, config);

    useEffect(() => {
      const error = swr.error;
      if (error?.response?.status === 404) {
        setNotFound(true);
      }
    }, [swr.error, setNotFound]);

    return swr;
  };

export const normalizeKey = ({
  key,
  pause = false,
  defaultKey,
}: {
  key?: Key;
  pause?: boolean;
  defaultKey: Key;
}) => {
  if (pause) {
    return null;
  }

  return key ?? defaultKey;
};

// Custom hook to persist data in localStorage
export function useSWRWithLocalStorage<Data, Error>(
  key: string,
  fetcher: Fetcher<Data>,
  options?: SWRConfiguration<Data, Error>,
): SWRResponse<Data, Error> {
  // Load the initial data from localStorage
  const initialData = (() => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : undefined;
  })();

  // Use SWR with initial data loaded from localStorage
  const swrResponse = useSWR<Data, Error>(key, fetcher, {
    ...options,
    fallbackData: initialData, // Use fallbackData to pass the localStorage data
  });

  useEffect(() => {
    if (swrResponse.data) {
      // Persist the fetched data in localStorage
      localStorage.setItem(key, JSON.stringify(swrResponse.data));
    }
  }, [key, swrResponse.data]);

  return swrResponse;
}
