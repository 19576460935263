import { useState } from 'react';
import AppPageHeader from '@/components/AppPageHeader/AppPageHeader';
import PageBody from '@/components/PageBody/PageBody';
import { AccountForm } from '@/components/EventsProcessing/AccountForm/AccountForm';
import { useParams, useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import classes from './EventsProcessingCreateAccountPage.module.scss';
import { AccountV1Dto } from '@@/generated/openapi';
import { waitForPipeline } from '@/helpers/waitForPipeline';

export const EventsProcessingCreateAccountPage = () => {
  const client = useApiClient();
  const { app, env } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { trigger } = useSWRMutation(
    ['account-create'],
    async (_key: [string], { arg: data }: { arg: AccountV1Dto }) =>
      await waitForPipeline<AccountV1Dto>(
        () => client.createAccountV1(data, app ?? '', env ?? ''),
        (id) => client.getPipeline(id),
        () => client.getAccountV1(data.id ?? '', app ?? '', env ?? ''),
      ),
  );

  const handleSubmit = async (data: AccountV1Dto) => {
    try {
      setIsLoading(true);
      const response = await trigger(data);
      if (response?.id) {
        navigate(
          `/app/${app}/${env}/events-processing/accounts/${response.id}`,
        );
      }
    } catch (error) {
      console.error('Failed to create account:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppPageHeader />
      <PageBody
        className={classes.pageBody}
        title="Create Kafka Account"
        padding
      >
        <AccountForm
          onSubmit={handleSubmit}
          isLoading={isLoading}
          initialData={{
            applicationCode: app ?? '',
          }}
        />
      </PageBody>
    </>
  );
};
