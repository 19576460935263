import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { useController } from 'react-hook-form';
import ReadOnlySelect from '@/components/Select/ReadOnlySelect/ReadOnlySelect';
import { useFieldError } from '@/hooks/useFieldError';
import { SelectProps } from '@/interfaces/fields';
import Markdown from '../Markdown/Markdown';
import classes from './Select.module.scss';
const Select = ({
  name,
  text,
  options,
  handleChange,
  valueOnly,
  className,
  ...rest
}: SelectProps) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name });
  const error = useFieldError(name, text);

  // McSelect doesn't update properly when options change.
  // Use key to force react to create new McSelect when options change.
  const key = options.map((o) => o.value).join();

  if (rest.readonly) {
    return (
      <ReadOnlySelect
        value={valueOnly ? [{ label: value, value }] : value}
        name={name}
        text={text}
        options={options}
        {...rest}
      />
    );
  }

  return (
    <div className={className}>
      <McSelect
        key={key}
        {...rest}
        input={(e: Event) => {
          const selectedOption = options.find(
            (o) => o.value === (e.target as HTMLSelectElement).value,
          );
          if (valueOnly) {
            onChange(selectedOption?.value);
            handleChange && handleChange(selectedOption?.value || '');
            return;
          }
          onChange(selectedOption ? [selectedOption] : []);
          handleChange && handleChange(selectedOption);
        }}
        onBlur={onBlur}
        errormessage={error ?? ''}
        invalid={!!error}
        label={text.label}
        placeholder={text.placeholder}
        data-testid={name}
        value={!valueOnly ? value?.[0]?.value || '' : value}
      >
        {options.map((o) => (
          <McOption
            key={o.value}
            value={o.value}
            data-testid={`${name}-option-${o.value}`}
            sublabel={o.secondary ?? ''}
          >
            {o.label}
          </McOption>
        ))}
      </McSelect>
      {text.hint && <Markdown className={classes.hint}>{text.hint}</Markdown>}
    </div>
  );
};

export default Select;
