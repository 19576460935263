/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface ProductMetaResponseDto
 */
export interface ProductMetaResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProductMetaResponseDto
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMetaResponseDto
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMetaResponseDto
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMetaResponseDto
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMetaResponseDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMetaResponseDto
     */
    status?: ProductMetaResponseDtoStatusEnum;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ProductMetaResponseDto
     */
    owners?: Set<OwnerDto>;
}


/**
 * @export
 */
export const ProductMetaResponseDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Deleted: 'Deleted'
} as const;
export type ProductMetaResponseDtoStatusEnum = typeof ProductMetaResponseDtoStatusEnum[keyof typeof ProductMetaResponseDtoStatusEnum];


/**
 * Check if a given object implements the ProductMetaResponseDto interface.
 */
export function instanceOfProductMetaResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductMetaResponseDtoFromJSON(json: any): ProductMetaResponseDto {
    return ProductMetaResponseDtoFromJSONTyped(json, false);
}

export function ProductMetaResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductMetaResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'productCode': !exists(json, 'productCode') ? undefined : json['productCode'],
        'productType': !exists(json, 'productType') ? undefined : json['productType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
    };
}

export function ProductMetaResponseDtoToJSON(value?: ProductMetaResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'productName': value.productName,
        'productCode': value.productCode,
        'productType': value.productType,
        'description': value.description,
        'status': value.status,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
    };
}

