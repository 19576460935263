import classes from './Header.module.scss';
import AccountDropdown from '@/components/Header/AccountDropdown/AccountDropdown';
import LinkButton, {
  LinkButtonVariant,
} from '@/components/LinkButton/LinkButton';
import { header as text } from '@text';
import MdpLogo from '@/assets/mdp-logo.svg?react';

function Header() {
  return (
    <header className={classes.header}>
      <Link to={'/'} className={classes.logo} aria-label={'MDP Home Page'}>
        <img
          src={'/maersk-badge.svg'}
          alt={''}
          className={classes.maerskBadge}
        />
        <MdpLogo className={classes.mdpLogo} />
      </Link>
      <nav aria-label={'Navigation Links'} className={classes.nav}>
        <LinkButton
          to={'/my-apps'}
          icon={'module-1'}
          variant={LinkButtonVariant.Plain}
        >
          {text.apps}
        </LinkButton>
        <LinkButton
          to={'/my-teams'}
          icon={'people'}
          variant={LinkButtonVariant.Plain}
        >
          {text.teams}
        </LinkButton>
        <LinkButton
          external={true}
          to={'/docs/'}
          icon={'file'}
          variant={LinkButtonVariant.Plain}
        >
          {text.docs}
        </LinkButton>
        {/* <McButton
          icon={'magnifying-glass'}
          variant={'plain'}
          appearance={'neutral'}
          arialabel={'Search'}
          hiddenlabel={true}
        /> */}

        <AccountDropdown />
      </nav>
    </header>
  );
}

export default Header;
