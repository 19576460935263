import { ProblemDetailDto } from '@@/generated/openapi';
import { parseTemplateString } from '@/helpers/utils';
import { defaultErrorMessages } from '@@/generated/text/text.json';

export const isProblemDetail = (error: unknown): error is ProblemDetailDto => {
  if (!error) {
    return false;
  }
  return (error as ProblemDetailDto).traceId !== undefined;
};

/*
  Takes nested error object:
    {
      observability: {
        metrics: {
          enabled: {
            message: 'Some error'
          }
        }
      }
    }

  Converts to:
    {
      observability.metrics.enabled: {
        message: 'Some error'
      }
    }
 */
export const flattenErrors = (
  obj: Record<string, unknown>,
  prefix?: string,
  result: Record<string, unknown> = {},
) => {
  if (prefix && Object.prototype.hasOwnProperty.call(obj, 'ref')) {
    result[prefix] = obj;
    return result;
  }

  const newPrefix = prefix ? `${prefix}.` : '';

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        (Array.isArray(obj[key]) ||
          Object.prototype.toString.call(obj[key]) === '[object Object]')
      ) {
        flattenErrors(
          obj[key] as Record<string, unknown>,
          `${newPrefix}${key}`,
          result,
        );
      } else {
        result[`${newPrefix}${key}`] = obj[key];
      }
    }
  }

  return result;
};

export const hasErrorMap = (error: unknown) => {
  return isProblemDetail(error) && !!error.errors && error.errors.length > 0;
};

export const getErrorMessage = (
  code: string,
  text: Record<string, string>,
  context: object = {},
) => {
  if (text[code]) {
    return parseTemplateString(text[code], context);
  }

  const errorTemplate =
    defaultErrorMessages[code as keyof typeof defaultErrorMessages];

  if (errorTemplate) {
    return parseTemplateString(errorTemplate, context);
  }

  return code;
};
