export interface UrlParams {
  app?: string;
  env?: string;
  service?: string;
  revision?: string;
  pipeline?: string;
  team?: string;
  secretGroup?: string;
  database?: string;
  topic?: string;
  account?: string;
  schema?: string;
}

const normalizedArgs = (params: UrlParams, args: UrlParams = {}) => {
  return Object.assign({}, params, args);
};

export const getUrlFromParams = (params: UrlParams) => {
  const { app, env, service, team, revision, pipeline } = params;
  if (team) {
    return getTeamUrl(params);
  }
  if (pipeline) {
    return getPipelineUrl(params);
  }
  return [
    `/app/${app}`,
    env ? `/${env}` : '',
    service ? `/service/${service}` : '',
    revision ? `/revision/${revision}` : '',
  ].join('');
};

export const getAppUrl = (params: UrlParams, args?: Pick<UrlParams, 'app'>) => {
  const { app } = normalizedArgs(params, args);
  return `/app/${app}`;
};

export const getAppEnvUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env'>,
) => {
  const { env } = normalizedArgs(params, args);
  return `${getAppUrl(params, args)}/${env}`;
};

export const getServiceUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service'>,
) => {
  const { service } = normalizedArgs(params, args);
  return `${getAppEnvUrl(params, args)}/service/${service}`;
};

export const getServiceSettingsUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service'>,
) => {
  return `${getServiceUrl(params, args)}/settings`;
};

export const getAppServicesUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env'>,
) => {
  return `${getAppEnvUrl(params, args)}/service`;
};

export const getAppSecretGroupsUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env'>,
) => {
  return `${getAppEnvUrl(params, args)}/secret-group`;
};

export const getServicePipelinesUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service' | 'revision'>,
) => {
  return `${getServiceUrl(params, args)}/pipelines`;
};

export const getServiceRevisionsUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service'>,
) => {
  return `${getServiceUrl(params, args)}/revisions`;
};

export const getSeverGroupUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service' | 'revision'>,
) => {
  const { revision } = normalizedArgs(params, args);
  return `${getServiceUrl(params, args)}/revision/${revision}`;
};

export const getPipelineUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service' | 'pipeline'>,
) => {
  const { pipeline } = normalizedArgs(params, args);
  return `${getServiceUrl(params, args)}/pipeline/${pipeline}`;
};

export const getTeamUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'team'>,
) => {
  const { team } = normalizedArgs(params, args);
  return `/team/${team}`;
};

export const getNewSecretGroupUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env'>,
) => {
  return `${getAppEnvUrl(params, args)}/new-secret-group`;
};

export const getSecretGroupUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'secretGroup'>,
) => {
  const { secretGroup } = normalizedArgs(params, args);
  return `${getAppEnvUrl(params, args)}/secret-group/${encodeURIComponent(
    secretGroup || '',
  )}`;
};

export const getNewBlankServiceUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env'>,
) => {
  return `${getAppEnvUrl(params, args)}/new-service`;
};

export const getCopyServiceUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service'>,
) => {
  return `${getServiceUrl(params, args)}/copy-service`;
};

export const getCopySecretGroupUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'service' | 'secretGroup'>,
) => {
  return `${getSecretGroupUrl(params, args)}/copy`;
};

export const getMacUrl = (macId: string) => {
  if (window.envVars.VITE_API_URL.includes('.maersk.io')) {
    return `https://mac.maersk-digital.net/application/${macId}`;
  }
  return `https://mac.maersk-digital.dev/application/${macId}`;
};

export const getDatabasesUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env'>,
) => {
  return `${getAppEnvUrl(params, args)}/database`;
};

export const getDatabaseUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'database'>,
) => {
  const { database } = normalizedArgs(params, args);
  return `${getAppEnvUrl(params, args)}/database/${database}`;
};

export const getTopicUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'topic'>,
) => {
  const { topic } = normalizedArgs(params, args);
  return `${getAppEnvUrl(params, args)}/events-processing/topics/${topic}`;
};

export const getAccountUrl = ({
  app,
  env,
  account,
}: {
  app?: string;
  env?: string;
  account: string;
}) => {
  return `${getAppEnvUrl({ app, env })}/events-processing/accounts/${account}`;
};

export const getSchemaUrl = (
  params: UrlParams,
  args?: Pick<UrlParams, 'app' | 'env' | 'schema'>,
) => {
  const { schema } = normalizedArgs(params, args);
  return `${getAppEnvUrl(params, args)}/events-processing/schemas/${schema}`;
};
