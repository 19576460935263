import { parseTemplateString } from '@/helpers/utils';
import { serviceSettings, regions } from '@text';

export const getTextMessage = (
  message: string,
  context: Record<string, unknown>,
) => {
  return parseTemplateString(message, context);
};

export function renderSettingsLabel(path?: string[], includeFullName = true) {
  if (!path) {
    return '';
  }
  const name = path.join('.');
  const key = Object.keys(serviceSettings).find((key) => {
    const fieldName = key.replace('.template', '');
    const pathName = name.replace('.template', '');
    return pathName.includes(fieldName);
  }) as keyof typeof serviceSettings;

  if (!key) {
    return name;
  }

  const field = serviceSettings[key] || {};
  if ('fullName' in field && includeFullName) {
    return field.fullName;
  }

  if ('label' in field) {
    return field.label;
  }
  return name;
}

export const getRegion = (region: string): string => {
  return regions[region.toUpperCase() as keyof typeof regions].label || region;
};
