/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface ProductRequestDto
 */
export interface ProductRequestDto {
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ProductRequestDto
     */
    owners: Set<OwnerDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestDto
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestDto
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestDto
     */
    source: ProductRequestDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestDto
     */
    status?: ProductRequestDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestDto
     */
    platformId: string;
}


/**
 * @export
 */
export const ProductRequestDtoSourceEnum = {
    Mac: 'MAC',
    Eac: 'EAC',
    Snow: 'SNOW',
    Admiral: 'ADMIRAL',
    Mdp: 'MDP'
} as const;
export type ProductRequestDtoSourceEnum = typeof ProductRequestDtoSourceEnum[keyof typeof ProductRequestDtoSourceEnum];

/**
 * @export
 */
export const ProductRequestDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Deleted: 'Deleted'
} as const;
export type ProductRequestDtoStatusEnum = typeof ProductRequestDtoStatusEnum[keyof typeof ProductRequestDtoStatusEnum];


/**
 * Check if a given object implements the ProductRequestDto interface.
 */
export function instanceOfProductRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "owners" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "platformId" in value;

    return isInstance;
}

export function ProductRequestDtoFromJSON(json: any): ProductRequestDto {
    return ProductRequestDtoFromJSONTyped(json, false);
}

export function ProductRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'owners': (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'productName': json['productName'],
        'productCode': !exists(json, 'productCode') ? undefined : json['productCode'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'source': json['source'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'platformId': json['platformId'],
    };
}

export function ProductRequestDtoToJSON(value?: ProductRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'owners': (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'productName': value.productName,
        'productCode': value.productCode,
        'description': value.description,
        'source': value.source,
        'status': value.status,
        'platformId': value.platformId,
    };
}

