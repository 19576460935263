/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationInfoDto } from './ApplicationInfoDto';
import {
    ApplicationInfoDtoFromJSON,
    ApplicationInfoDtoFromJSONTyped,
    ApplicationInfoDtoToJSON,
} from './ApplicationInfoDto';
import type { PlatformInfoDto } from './PlatformInfoDto';
import {
    PlatformInfoDtoFromJSON,
    PlatformInfoDtoFromJSONTyped,
    PlatformInfoDtoToJSON,
} from './PlatformInfoDto';
import type { PortfolioInfoDto } from './PortfolioInfoDto';
import {
    PortfolioInfoDtoFromJSON,
    PortfolioInfoDtoFromJSONTyped,
    PortfolioInfoDtoToJSON,
} from './PortfolioInfoDto';

/**
 * 
 * @export
 * @interface DashboardCountResponseDto
 */
export interface DashboardCountResponseDto {
    /**
     * 
     * @type {ApplicationInfoDto}
     * @memberof DashboardCountResponseDto
     */
    applicationInfo?: ApplicationInfoDto;
    /**
     * 
     * @type {PlatformInfoDto}
     * @memberof DashboardCountResponseDto
     */
    platformInfo?: PlatformInfoDto;
    /**
     * 
     * @type {PortfolioInfoDto}
     * @memberof DashboardCountResponseDto
     */
    portfolioInfo?: PortfolioInfoDto;
    /**
     * 
     * @type {number}
     * @memberof DashboardCountResponseDto
     */
    appOnboardedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCountResponseDto
     */
    days?: number;
}

/**
 * Check if a given object implements the DashboardCountResponseDto interface.
 */
export function instanceOfDashboardCountResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardCountResponseDtoFromJSON(json: any): DashboardCountResponseDto {
    return DashboardCountResponseDtoFromJSONTyped(json, false);
}

export function DashboardCountResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardCountResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationInfo': !exists(json, 'applicationInfo') ? undefined : ApplicationInfoDtoFromJSON(json['applicationInfo']),
        'platformInfo': !exists(json, 'platformInfo') ? undefined : PlatformInfoDtoFromJSON(json['platformInfo']),
        'portfolioInfo': !exists(json, 'portfolioInfo') ? undefined : PortfolioInfoDtoFromJSON(json['portfolioInfo']),
        'appOnboardedCount': !exists(json, 'appOnboardedCount') ? undefined : json['appOnboardedCount'],
        'days': !exists(json, 'days') ? undefined : json['days'],
    };
}

export function DashboardCountResponseDtoToJSON(value?: DashboardCountResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationInfo': ApplicationInfoDtoToJSON(value.applicationInfo),
        'platformInfo': PlatformInfoDtoToJSON(value.platformInfo),
        'portfolioInfo': PortfolioInfoDtoToJSON(value.portfolioInfo),
        'appOnboardedCount': value.appOnboardedCount,
        'days': value.days,
    };
}

