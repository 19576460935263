import { ReactNode } from 'react';
import classes from './Main.module.css';

interface MainProps {
  children: ReactNode;
}

function Main({ children }: MainProps) {
  return (
    <main id={'main'} className={classes.main}>
      {children}
    </main>
  );
}

export default Main;
