import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper/components-core/mc-loading-indicator';
import classes from './AuthInProgress.module.css';

export const AuthInProgress = () => {
  return (
    <div className={classes.container}>
      <McLoadingIndicator
        variant="spinner"
        fit="large"
        label="Authenticating"
      />
    </div>
  );
};
