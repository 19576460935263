import useUser from '@/hooks/useUser';
import { useApp } from './useApp';
import { useTeam } from './useTeam';

export const useUserIsAppOwner = () => {
  const user = useUser();
  const { data: app } = useApp();
  const { data } = useTeam({
    args: [app?.spec.teamRef.name || ''],
    pause: !app,
  });

  if (!app?.spec.teamRef) {
    return false;
  }

  return Array.from(data?.ownerIds || []).some(
    (owner: string) => owner === user.id,
  );
};
