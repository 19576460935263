import {
  PipelineStatusV1Dto,
  ResponseError as MttsResponseError,
} from '@@/generated/mtts/openapi';

import { PipelineV1Dto, ResponseError } from '@@/generated/openapi';

export async function waitForPipeline<T>(
  postMethod: () => Promise<PipelineStatusV1Dto | PipelineV1Dto>,
  getPipelineStatus: (
    id: string,
  ) => Promise<PipelineStatusV1Dto | PipelineV1Dto>,
  getMethod?: () => Promise<T>,
): Promise<T | null> {
  const pipeline = await postMethod();
  const pipelineId =
    'pipelineId' in pipeline ? pipeline.pipelineId : pipeline.spec.id;

  while (true) {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before the next attempt
    try {
      const pipelineResponse = await getPipelineStatus(String(pipelineId));

      const pipelineStatus =
        'status' in pipelineResponse &&
        typeof pipelineResponse.status === 'string'
          ? pipelineResponse.status
          : pipelineResponse.status?.state;
      if (pipelineStatus === 'FAILED') {
        throw {
          status: 500,
          title: 'Pipeline Execution Failed',
          detail: 'The pipeline execution failed to complete successfully',
          errors: [
            {
              code: 'PIPELINE_FAILED',
              field: 'pipeline',
              message: 'Pipeline execution failed',
            },
          ],
        };
      }
      if (pipelineStatus !== 'COMPLETED') {
        continue;
      }
      const response = getMethod ? await getMethod() : null;
      return response;
    } catch (e: unknown) {
      if (
        (e instanceof MttsResponseError || e instanceof ResponseError) &&
        e.response.status === 404
      ) {
        continue;
      } else {
        console.error(e);
        throw e;
      }
    }
  }
}
