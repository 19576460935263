import { UserProviderInterface } from '@/providers/User';
import { IdentityV1Dto, TeamV1Dto } from '@@/generated/mtts/openapi';

export const userBelongsToTeam = (
  user: UserProviderInterface,
  team?: TeamV1Dto,
) => {
  if (!team) {
    return false;
  }

  return user.teamRefs.some((id) => id === team.id);
};

export const getUsernameByEmailAddress = (email: string) => {
  return email.split('@')[0].toLowerCase().replace(/\./g, '-');
};

export const compareUserName = (a: IdentityV1Dto, b: IdentityV1Dto) => {
  if (!a.displayName || !b.displayName) {
    return 0;
  }
  if (a.displayName < b.displayName) {
    return -1;
  }

  if (a.displayName > b.displayName) {
    return 1;
  }

  return 0;
};
