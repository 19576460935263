import { createContext, ReactNode } from 'react';
import { Configuration, DefaultApi } from '@@/generated/mtts/openapi';
import { useMsal } from '@azure/msal-react';
import { msalMiddleware } from './ApiClient';

export const MttsApiClientContext = createContext<DefaultApi>(new DefaultApi());

interface MttsApiClientProviderProps {
  children: ReactNode;
}

const MttsApiClientProvider = ({ children }: MttsApiClientProviderProps) => {
  const msal = useMsal();
  const [client, setClient] = useState<DefaultApi>(new DefaultApi());
  const [clientIsReady, setClientIsReady] = useState(false);

  useEffect(() => {
    function setupClient() {
      const config = new Configuration({
        basePath: window.envVars.VITE_MTTS_API_URL,
        middleware: [msalMiddleware(msal)],
      });
      setClient(new DefaultApi(config));
      setClientIsReady(true);
    }

    void setupClient();
  }, [msal]);

  return (
    <MttsApiClientContext.Provider value={client}>
      {clientIsReady ? children : null}
    </MttsApiClientContext.Provider>
  );
};

export default MttsApiClientProvider;
