import { createContext, ReactNode, useContext, useReducer } from 'react';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { ToastAppearance } from '@maersk-global/mds-components-core/mc-toast/types';
import { IconNames } from '@maersk-global/icons';

interface ToastProviderProps {
  children: ReactNode;
}

interface ShowToastInterface {
  message: string;
  heading?: string;
  duration?: number;
  appearance?: ToastAppearance;
  icon?: IconNames;
}

interface ToastContextType {
  showToast: (props: ShowToastInterface) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface ToastProps {
  id: number;
  message: string;
  heading?: string;
  duration?: number;
  appearance?: ToastAppearance;
  icon?: IconNames;
  onToastClose?: () => void;
}

type ToastAction =
  | { type: 'ADD_TOAST'; payload: Omit<ToastProps, 'id'> }
  | { type: 'REMOVE_TOAST'; payload: { id: number } };

const toastReducer = (state: ToastProps[], action: ToastAction) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return [...state, { ...action.payload, id: Date.now() }];
    case 'REMOVE_TOAST':
      return state.filter((toast) => {
        if (toast.id === action.payload.id && toast.onToastClose) {
          toast.onToastClose();
        }
        return toast.id !== action.payload.id;
      });
    default:
      return state;
  }
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, dispatch] = useReducer(toastReducer, []);

  const showToast = ({
    message,
    duration = 5000,
    appearance = 'info',
    icon,
    heading,
  }: ShowToastInterface) => {
    dispatch({
      type: 'ADD_TOAST',
      payload: { message, duration, appearance, icon, heading },
    });
  };

  const closeToast = (id: number) => {
    dispatch({ type: 'REMOVE_TOAST', payload: { id } });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          zIndex: 90000,
        }}
      >
        {toasts.map(({ id, appearance, duration, message, icon, heading }) => (
          <McToast
            key={id}
            open={true}
            position="bottom-right"
            appearance={appearance}
            duration={duration}
            close={() => closeToast(id)}
          >
            <McNotification body={message} icon={icon} heading={heading} />
          </McToast>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
