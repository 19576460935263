/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationResponseDto } from './ApplicationResponseDto';
import {
    ApplicationResponseDtoFromJSON,
    ApplicationResponseDtoFromJSONTyped,
    ApplicationResponseDtoToJSON,
} from './ApplicationResponseDto';
import type { DashboardCountResponseDto } from './DashboardCountResponseDto';
import {
    DashboardCountResponseDtoFromJSON,
    DashboardCountResponseDtoFromJSONTyped,
    DashboardCountResponseDtoToJSON,
} from './DashboardCountResponseDto';

/**
 * 
 * @export
 * @interface ApplicationListDto
 */
export interface ApplicationListDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationListDto
     */
    totalRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationListDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationListDto
     */
    currentPage?: number;
    /**
     * 
     * @type {Array<ApplicationResponseDto>}
     * @memberof ApplicationListDto
     */
    content?: Array<ApplicationResponseDto>;
    /**
     * 
     * @type {DashboardCountResponseDto}
     * @memberof ApplicationListDto
     */
    count?: DashboardCountResponseDto;
}

/**
 * Check if a given object implements the ApplicationListDto interface.
 */
export function instanceOfApplicationListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationListDtoFromJSON(json: any): ApplicationListDto {
    return ApplicationListDtoFromJSONTyped(json, false);
}

export function ApplicationListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(ApplicationResponseDtoFromJSON)),
        'count': !exists(json, 'count') ? undefined : DashboardCountResponseDtoFromJSON(json['count']),
    };
}

export function ApplicationListDtoToJSON(value?: ApplicationListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalRecords': value.totalRecords,
        'totalPages': value.totalPages,
        'currentPage': value.currentPage,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(ApplicationResponseDtoToJSON)),
        'count': DashboardCountResponseDtoToJSON(value.count),
    };
}

