import { RumInit } from '@maersk-global/telemetry-web-sdk';

const initRUM = function () {
  const apiKey = window.envVars.VITE_RUM_CLIENT_KEY;
  const name = window.envVars.VITE_RUM_APP_NAME;
  if (!apiKey || !name) return;

  RumInit({
    app: {
      name,
      version: '1.0.0',
      environment: name,
    },
    apiKey,
    debug: true, // false: PractivityLog showing log,error,etc captured in browser console
    traceLog: false, // Sends Traces to Loki ( For easy querying of traces)
  });
};

export default initRUM;
