export const loadTheme = async () => {
  const theme = getThemePreference();
  let css;

  // Theme variables are not scoped in any way.
  // We need to make sure only one theme is loaded at a time.
  // Load the css content and then inject into style tag manually.
  if (theme === 'light') {
    css = await import(
      `@maersk-global/mds-design-tokens/maersk/light-accessible/css/design-tokens-px.css?inline`
    );
  } else {
    css = await import(
      `@maersk-global/mds-design-tokens/maersk/dark/css/design-tokens-px.css?inline`
    );
  }

  const existingStyleTag = document.getElementById('theme');

  if (existingStyleTag) {
    existingStyleTag.innerHTML = css.default;
  } else {
    const styleTag = document.createElement('style');
    styleTag.id = 'theme';
    styleTag.innerHTML = css.default;
    document.head.appendChild(styleTag);
  }

  // Adds dark class to :root. Useful for conditional css.
  if (theme === 'dark') {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }

  return theme;
};

export const toggleThemePreference = async () => {
  const theme = getThemePreference();

  if (theme === 'light') {
    setThemePreference('dark');
  } else {
    setThemePreference('light');
  }

  await loadTheme();

  return theme === 'light' ? 'dark' : 'light';
};

export const getThemePreference = () => {
  const defaultTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
  return window.localStorage.getItem('theme') ?? defaultTheme;
};

export const setThemePreference = (theme: string) => {
  window.localStorage.setItem('theme', theme);
};
