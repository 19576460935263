/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MvcCategoryResponseDto } from './MvcCategoryResponseDto';
import {
    MvcCategoryResponseDtoFromJSON,
    MvcCategoryResponseDtoFromJSONTyped,
    MvcCategoryResponseDtoToJSON,
} from './MvcCategoryResponseDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface ApplicationMetaResponseDto
 */
export interface ApplicationMetaResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaResponseDto
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaResponseDto
     */
    applicationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaResponseDto
     */
    description?: string;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ApplicationMetaResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationMetaResponseDto
     */
    targetDecommissioningDate?: Date;
    /**
     * 
     * @type {MvcCategoryResponseDto}
     * @memberof ApplicationMetaResponseDto
     */
    criticalityCategory?: MvcCategoryResponseDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaResponseDto
     */
    status?: ApplicationMetaResponseDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationMetaResponseDto
     */
    nameVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationMetaResponseDto
     */
    canEdit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaResponseDto
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationMetaResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaResponseDto
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationMetaResponseDto
     */
    modifiedDate?: Date;
}


/**
 * @export
 */
export const ApplicationMetaResponseDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Deleted: 'Deleted'
} as const;
export type ApplicationMetaResponseDtoStatusEnum = typeof ApplicationMetaResponseDtoStatusEnum[keyof typeof ApplicationMetaResponseDtoStatusEnum];


/**
 * Check if a given object implements the ApplicationMetaResponseDto interface.
 */
export function instanceOfApplicationMetaResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationMetaResponseDtoFromJSON(json: any): ApplicationMetaResponseDto {
    return ApplicationMetaResponseDtoFromJSONTyped(json, false);
}

export function ApplicationMetaResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationMetaResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'targetDecommissioningDate': !exists(json, 'targetDecommissioningDate') ? undefined : (new Date(json['targetDecommissioningDate'])),
        'criticalityCategory': !exists(json, 'criticalityCategory') ? undefined : MvcCategoryResponseDtoFromJSON(json['criticalityCategory']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'nameVerified': !exists(json, 'nameVerified') ? undefined : json['nameVerified'],
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
    };
}

export function ApplicationMetaResponseDtoToJSON(value?: ApplicationMetaResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'description': value.description,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'targetDecommissioningDate': value.targetDecommissioningDate === undefined ? undefined : (value.targetDecommissioningDate.toISOString().substring(0,10)),
        'criticalityCategory': MvcCategoryResponseDtoToJSON(value.criticalityCategory),
        'status': value.status,
        'nameVerified': value.nameVerified,
        'canEdit': value.canEdit,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
    };
}

