import { createContext, ReactNode } from 'react';
import { Configuration, DefaultApi, Middleware } from '@@/generated/openapi';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const ApiClientContext = createContext<DefaultApi>(new DefaultApi());

interface ApiClientProviderProps {
  children: ReactNode;
}
const scopes = ['user.read'];

export const msalMiddleware = (msal: IMsalContext): Middleware => {
  let isRedirectInProgress = false;
  return {
    pre: async (context) => {
      let token;

      try {
        token = await msal.instance.acquireTokenSilent({
          // TODO: Removing the custom scope from the silent token request, as it causes refreshes
          scopes: ['user.read'],
          account: msal.accounts[0],
        });
      } catch (e: unknown) {
        if (e instanceof InteractionRequiredAuthError) {
          if (!isRedirectInProgress) {
            isRedirectInProgress = true;
            token = await msal.instance.acquireTokenRedirect({
              scopes,
              account: msal.accounts[0],
            });
            isRedirectInProgress = false;
          }
        }
      }

      return {
        ...context,
        init: {
          ...context.init,
          headers: new Headers({
            ...context.init.headers,
            Authorization: `Bearer ${token?.idToken ?? ''}`,
          }),
        },
      };
    },
    post: async (context) => {
      if (context.response.status === 401) {
        if (!isRedirectInProgress) {
          isRedirectInProgress = true;
          await msal.instance.acquireTokenRedirect({
            scopes,
            account: msal.accounts[0],
          });
          isRedirectInProgress = false;
        }
      }

      return context.response;
    },
  };
};

const ApiClientProvider = ({ children }: ApiClientProviderProps) => {
  const msal = useMsal();
  const [client, setClient] = useState<DefaultApi>(new DefaultApi());
  const [clientIsReady, setClientIsReady] = useState(false);

  useEffect(() => {
    function setupClient() {
      const config = new Configuration({
        basePath: window.envVars.VITE_API_URL,
        middleware: [msalMiddleware(msal)],
      });
      setClient(new DefaultApi(config));
      setClientIsReady(true);
    }

    void setupClient();
  }, [msal]);

  return (
    <ApiClientContext.Provider value={client}>
      {clientIsReady ? children : null}
    </ApiClientContext.Provider>
  );
};

export default ApiClientProvider;
