/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductResponseDto } from './ProductResponseDto';
import {
    ProductResponseDtoFromJSON,
    ProductResponseDtoFromJSONTyped,
    ProductResponseDtoToJSON,
} from './ProductResponseDto';

/**
 * 
 * @export
 * @interface ProductListDto
 */
export interface ProductListDto {
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    totalRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    currentPage?: number;
    /**
     * 
     * @type {Array<ProductResponseDto>}
     * @memberof ProductListDto
     */
    content?: Array<ProductResponseDto>;
}

/**
 * Check if a given object implements the ProductListDto interface.
 */
export function instanceOfProductListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductListDtoFromJSON(json: any): ProductListDto {
    return ProductListDtoFromJSONTyped(json, false);
}

export function ProductListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(ProductResponseDtoFromJSON)),
    };
}

export function ProductListDtoToJSON(value?: ProductListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalRecords': value.totalRecords,
        'totalPages': value.totalPages,
        'currentPage': value.currentPage,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(ProductResponseDtoToJSON)),
    };
}

