// ui/src/providers/PipelineProvider.tsx
import { CustomSwrHook } from '@/interfaces/swr';
import {
  DefaultApi,
  PipelineStatusV1DtoStateEnum,
  ResponseError,
} from '@@/generated/openapi';
import React, { createContext, useContext, useState, useEffect } from 'react';
import useSWR from 'swr';

export enum PipelineTypes {
  QuickstartApp,
  QuickstartService,
}

export interface Pipeline {
  type: PipelineTypes;
  id: string;
}

interface PipelineContextProps {
  pipelines: Pipeline[];
  addPipeline: (pipeline: Pipeline) => void;
  removePipeline: (id: string) => void;
}

const PipelineContext = createContext<PipelineContextProps | undefined>(
  undefined,
);

export const PipelineProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pipelines, setPipelines] = useState<Pipeline[]>(() => {
    const storedPipelines = localStorage.getItem('pipelines');
    return storedPipelines ? JSON.parse(storedPipelines) : [];
  });

  useEffect(() => {
    localStorage.setItem('pipelines', JSON.stringify(pipelines));
  }, [pipelines]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'pipelines') {
        const newPipelines: Pipeline[] = event.newValue
          ? JSON.parse(event.newValue)
          : [];
        setPipelines(newPipelines);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const addPipeline = (pipeline: Pipeline) => {
    setPipelines((prevPipelines) => [
      { ...pipeline, timeStarted: new Date() },
      ...prevPipelines,
    ]);
  };

  const removePipeline = (id: string) => {
    setPipelines((prevPipelines) =>
      prevPipelines.filter((pipeline) => pipeline.id !== id),
    );
  };

  return (
    <PipelineContext.Provider
      value={{ pipelines, addPipeline, removePipeline }}
    >
      {children}
    </PipelineContext.Provider>
  );
};

export const useStoredPipeline = () => {
  const context = useContext(PipelineContext);

  if (!context) {
    throw new Error('useStoredPipeline must be used within a PipelineProvider');
  }
  return context;
};

export const useStoredPipelineData = ({
  // key,
  // args,
  // config,
  pause,
}: CustomSwrHook<
  | DefaultApi['getServiceQuickstartPipeline']
  | DefaultApi['getApplicationQuickstartPipeline']
> = {}) => {
  const { pipelines, removePipeline } = useStoredPipeline();
  const [shouldFetch, setShouldFetch] = useState(true);
  const client = useApiClient();

  return useSWR(
    pipelines[0]?.id ? ['quickstart-pipeline-progress', pipelines[0].id] : null,
    async () => {
      if (!pipelines.length) {
        return;
      }
      let pipelineResponse;

      try {
        pipelineResponse =
          pipelines[0].type === PipelineTypes.QuickstartApp
            ? await client.getApplicationQuickstartPipeline(pipelines[0].id)
            : await client.getServiceQuickstartPipeline(pipelines[0].id);

        if (
          pipelineResponse?.status?.state ===
            PipelineStatusV1DtoStateEnum.Completed ||
          pipelineResponse?.status?.state ===
            PipelineStatusV1DtoStateEnum.Failed
        ) {
          setShouldFetch(false);
        }
      } catch (error) {
        if (error instanceof ResponseError && error?.response.status === 404) {
          removePipeline(pipelines[0].id);
          return;
        }
      }

      return pipelineResponse;
    },
    {
      refreshInterval: 1000,
      pause: pause || !shouldFetch,
    },
  );
};
