import { createContext, ReactNode } from 'react';

export const NotFoundContext = createContext<
  [boolean, (notFound: boolean) => void]
>([
  false,
  () => {
    return;
  },
]);

interface NotFoundProps {
  children: ReactNode;
}

const NotFoundProvider = ({ children }: NotFoundProps) => {
  const notFound = useState(false);

  return (
    <NotFoundContext.Provider value={notFound}>
      {children}
    </NotFoundContext.Provider>
  );
};

export default NotFoundProvider;
