import { get, useFormContext } from 'react-hook-form';
import {
  getErrorMessage,
  hasErrorMap,
  isProblemDetail,
} from '@/helpers/errors';
import { parseTemplateString } from '@/helpers/utils';
import * as yup from 'yup';
import { FieldSchema } from '@/interfaces/yup';
import { isEqual } from 'lodash-es';

export const useFieldError = (
  name: string,
  text: { label: string },
  serverNames: string[] = [],
): string | undefined => {
  const {
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();
  const { error, addFieldNames, removeFieldNames } = useError();
  const uiError: { message: string } = get(errors, name);
  const values = watch();
  const value = getValues(name);
  const schema = useValidationSchema();
  const [valueWhenErrored, setValueWhenErrored] = useState<unknown>();

  useEffect(() => {
    addFieldNames([name, ...serverNames]);

    return () => {
      removeFieldNames([name, ...serverNames]);
    };
  }, [addFieldNames, removeFieldNames, name, serverNames]);

  useEffect(() => {
    if (error) {
      setValueWhenErrored(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const schemaParams = useMemo(() => {
    let fieldSchema: FieldSchema;

    try {
      fieldSchema = yup.reach(schema, name, values) as unknown as FieldSchema;
    } catch {
      return {};
    }

    return fieldSchema.tests?.reduce(
      (acc: Record<string, Record<string, unknown>>, cur) => {
        const options = cur.OPTIONS;
        if (options.params) {
          acc[options.name] = options.params;
        }

        return acc;
      },
      {},
    );
  }, [schema, name, values]);

  const context = {
    label: text.label,
    value: value,
    form: values,
    schemaParams,
  };

  if (uiError) {
    return parseTemplateString(uiError.message, context);
  }

  if (isProblemDetail(error) && hasErrorMap(error)) {
    const fieldError = error.errors?.find(
      (e) => e.field === name || serverNames.includes(e.field),
    );

    if (fieldError) {
      const dirtySinceErrored = !isEqual(valueWhenErrored, value);

      if (valueWhenErrored && dirtySinceErrored) {
        return;
      }

      return getErrorMessage(fieldError.code, text, context);
    }
  }

  return;
};
