import { FormInputGroup } from '@/components/FormInputGroup/FormInputGroup';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import Input from '@/components/Input/Input';
import Select from '@/components/Select/Select';
import Textarea from '@/components/Textarea/Textarea';
import {
  EnvironmentClassV1Dto,
  SchemaTypeV1Dto,
  SchemaV1Dto,
} from '@@/generated/openapi';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface SchemaFormProps {
  onSubmit: (data: SchemaV1Dto) => Promise<void>;
}

export const SchemaForm = ({ onSubmit }: SchemaFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const form = useForm<SchemaV1Dto>({
    defaultValues: {
      environmentClass: EnvironmentClassV1Dto.Nonprod,
      applicationCode: params.app ?? '',
      name: '',
      type: 'AVRO',
      content: '{}',
    },
  });
  const schema = yup.object({
    spec: yup.string().required('Schema is required'),
  });

  const handleSubmit = async (data: SchemaV1Dto) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormWrapper form={form} schema={schema}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormInputGroup width="normal">
          <Input
            disabled={isLoading}
            name="name"
            text={{
              label: 'Name',
              placeholder: 'Enter name',
              hint: 'Enter the name of the schema',
            }}
          />
          <Select
            disabled={isLoading}
            name="type"
            valueOnly
            text={{
              label: 'Schema Type',
              placeholder: 'Select schema type',
              hint: 'Select the type of the schema',
            }}
            options={Object.values(SchemaTypeV1Dto).map((type) => ({
              label: type,
              value: type,
            }))}
          />
          <Textarea
            disabled={isLoading}
            name="content"
            rows={10}
            text={{
              label: 'Value Schema',
              placeholder: 'Enter JSON schema',
              hint: 'Enter the schema specification in JSON format',
            }}
          />
        </FormInputGroup>
        <McButton type="submit" loading={isLoading}>
          Create Schema
        </McButton>
      </form>
    </FormWrapper>
  );
};
