import useSWR from 'swr';
import { DefaultApi } from '@@/generated/openapi';
import { CustomSwrHook } from '@/interfaces/swr';
import { normalizeKey } from '@/helpers/swr';

export const useKafkaSchemas = ({
  key,
  args,
  config,
  pause,
}: CustomSwrHook<DefaultApi['getSchemasV1']> = {}) => {
  const client = useApiClient();
  const params = useParams();

  const normalizedKey = normalizeKey({
    key,
    pause,
    defaultKey: ['kafka-schemas', params?.app, params?.env],
  });
  const normalizedArgs = args ?? [params?.app ?? '', params?.env ?? ''];

  return useSWR(
    normalizedKey,
    () => client.getSchemasV1(...normalizedArgs),
    config,
  );
};
