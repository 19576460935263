import PageHeader, {
  PageDetails,
  PageDetailsBody,
  PageDetailsColumn,
  PageDetailsHead,
  PageDetailsRow,
  PageName,
  PageType,
} from '@/components/PageHeader/PageHeader';
import classes from './AppPageHeader.module.scss';
import { useApp } from '@/hooks/useApp';
import { swrNotFoundHandler } from '@/helpers/swr';
import { appPage } from '@text';
import { getMacUrl, getTeamUrl } from '@/helpers/urls';
import { TeamChangeModal } from './TeamChangeModal/TeamChangeModal';

const AppPageHeader = () => {
  const { data: app } = useApp({ config: { use: [swrNotFoundHandler] } });
  const env = useNormalizedEnv();

  return (
    <PageHeader>
      <PageType>{appPage.pageType}</PageType>
      <PageName>
        <div className={classes.name} data-testid={'app-name'}>
          {app?.spec.displayName ?? app?.metadata.name}
        </div>
      </PageName>
      <PageDetails>
        <PageDetailsRow>
          <PageDetailsColumn>
            <PageDetailsHead>{appPage.detailTeam}</PageDetailsHead>
            <PageDetailsBody>
              <div className={classes.teamName}>
                <Link to={getTeamUrl({ team: app?.spec.teamRef.name })}>
                  {app?.spec.teamRef.name}
                </Link>
                <TeamChangeModal />
              </div>
            </PageDetailsBody>
          </PageDetailsColumn>
          <PageDetailsColumn>
            <PageDetailsHead>{appPage.detailEnv}</PageDetailsHead>
            <PageDetailsBody>
              {env === 'dev' ? 'Development' : 'Production'}
            </PageDetailsBody>
          </PageDetailsColumn>
        </PageDetailsRow>
        <PageDetailsRow>
          <PageDetailsColumn>
            <PageDetailsHead>{appPage.detailMacId}</PageDetailsHead>
            <PageDetailsBody>
              {app?.spec.macAppId ? (
                <a
                  target="_blank"
                  href={getMacUrl(app?.spec.macAppId)}
                  rel="noreferrer"
                >
                  {app?.spec.macAppId}
                </a>
              ) : (
                'Not set'
              )}
            </PageDetailsBody>
          </PageDetailsColumn>
        </PageDetailsRow>
      </PageDetails>
    </PageHeader>
  );
};

export default AppPageHeader;
