import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TopicV1Dto, EnvironmentClassV1Dto } from '@@/generated/openapi';
import Input from '@/components/Input/Input';
import TextArea from '@/components/Textarea/Textarea';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import InputStepper from '@/components/InputStepper/InputStepper';
import { useKafkaSchemas } from '@/hooks/useKafkaSchemas';
import { convertReactHookFormToMcInput } from '@/helpers/utils';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { FormInputGroup } from '@/components/FormInputGroup/FormInputGroup';

const schema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string(),
  spec: yup
    .object({
      partitionNumber: yup.number().required(),
      retentionMs: yup.number().required(),
    })
    .required(),
});

const defaultTopic: TopicV1Dto = {
  name: '',
  applicationCode: '',
  tags: [],
  environmentClass: 'NONPROD',
  description: '',
  spec: {
    dataSecurityClassification: 'INTERNAL',
    isSharable: true,
    partitionNumber: 3,
    retentionMs: 86400000,
    replicationFactor: 3,
    segmentBytes: 1073741824,
    cleanupPolicy: 'DELETE',
  },
};

type TopicFormProps = {
  initialData?: TopicV1Dto;
  onSubmit: (data: TopicV1Dto) => Promise<void>;
  onCancel?: () => void;
  isLoading?: boolean;
};

export const TopicForm = ({
  initialData = defaultTopic,
  onSubmit,
  onCancel,
  isLoading,
}: TopicFormProps) => {
  const form = useForm<TopicV1Dto>({
    // @ts-expect-error schema is not properly typed
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name ?? '',
      environmentClass:
        initialData?.environmentClass ?? EnvironmentClassV1Dto.Nonprod,
      description: initialData?.description ?? '',
      spec: initialData?.spec ?? defaultTopic.spec,
    },
  });

  const { data: schemas } = useKafkaSchemas();

  const handleSubmit = form.handleSubmit(async (data) => {
    await onSubmit(data);
  });

  return (
    <FormWrapper form={form} schema={schema}>
      <form onSubmit={handleSubmit}>
        <FormInputGroup width="normal">
          <Input
            name="name"
            text={{
              hint: 'Enter the topic name',
              label: 'Topic Name',
              placeholder: 'Enter topic name',
            }}
            disabled={isLoading}
            data-testid="topic-name"
          />

          <TextArea
            name="description"
            text={{
              hint: 'Enter a description for the topic',
              label: 'Description',
              placeholder: 'Enter description',
            }}
            data-testid="description"
            disabled={isLoading}
          />

          <InputStepper
            name="spec.partitionNumber"
            min={1}
            text={{
              label: 'Number of partitions',
              placeholder: '',
              hint: '',
            }}
            disabled={isLoading}
          />

          <InputStepper
            type="number"
            suffix="days"
            name="spec.retentionMs"
            calculateValue={(value) => value * 86400000}
            calculateDisplayValue={(value) => value / 86400000}
            min={1}
            text={{
              label: 'Retention period',
              placeholder: '',
              hint: '',
            }}
            disabled={isLoading}
          />

          <McSelect
            label="Key Schema"
            placeholder="Select schema"
            {...convertReactHookFormToMcInput(form.register('spec.keySchema'))}
            disabled={isLoading}
          >
            {schemas?.map((schema) => (
              <McOption
                key={schema.id}
                value={{
                  resourceId: schema.id,
                  applicationCode: schema.applicationCode,
                  resourceType: 'SCHEMA',
                }}
              >
                {schema.name || schema.id}
              </McOption>
            ))}
          </McSelect>

          <McSelect
            label="Value Schema"
            placeholder="Select schema"
            {...convertReactHookFormToMcInput(
              form.register('spec.valueSchema'),
            )}
            disabled={isLoading}
          >
            {schemas?.map((schema) => (
              <McOption
                key={schema.id}
                value={{
                  resourceId: schema.id,
                  applicationCode: schema.applicationCode,
                  resourceType: 'SCHEMA',
                }}
              >
                {schema.name || schema.id}
              </McOption>
            ))}
          </McSelect>

          {/* Add spec-specific form fields here */}

          <div className="form-actions">
            {onCancel && (
              <McButton
                type="button"
                variant="outlined"
                appearance="primary"
                onClick={onCancel}
                loading={isLoading}
              >
                Cancel
              </McButton>
            )}
            <McButton loading={isLoading} type="submit">
              Save Topic
            </McButton>
          </div>
        </FormInputGroup>
      </form>
    </FormWrapper>
  );
};
