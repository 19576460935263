/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Technical Team Service (MTTS)
 * Maersk Technical Team Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TeamStatusV1Dto } from './TeamStatusV1Dto';
import {
    TeamStatusV1DtoFromJSON,
    TeamStatusV1DtoFromJSONTyped,
    TeamStatusV1DtoToJSON,
} from './TeamStatusV1Dto';

/**
 * 
 * @export
 * @interface TeamV1Dto
 */
export interface TeamV1Dto {
    /**
     * 
     * @type {string}
     * @memberof TeamV1Dto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeamV1Dto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof TeamV1Dto
     */
    readonly azureGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamV1Dto
     */
    readonly azureGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamV1Dto
     */
    readonly githubTeamId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamV1Dto
     */
    readonly githubTeamName?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof TeamV1Dto
     */
    ownerIds: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof TeamV1Dto
     */
    memberIds?: Set<string>;
    /**
     * 
     * @type {TeamStatusV1Dto}
     * @memberof TeamV1Dto
     */
    status?: TeamStatusV1Dto;
}

/**
 * Check if a given object implements the TeamV1Dto interface.
 */
export function instanceOfTeamV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "ownerIds" in value;

    return isInstance;
}

export function TeamV1DtoFromJSON(json: any): TeamV1Dto {
    return TeamV1DtoFromJSONTyped(json, false);
}

export function TeamV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'azureGroupId': !exists(json, 'azureGroupId') ? undefined : json['azureGroupId'],
        'azureGroupName': !exists(json, 'azureGroupName') ? undefined : json['azureGroupName'],
        'githubTeamId': !exists(json, 'githubTeamId') ? undefined : json['githubTeamId'],
        'githubTeamName': !exists(json, 'githubTeamName') ? undefined : json['githubTeamName'],
        'ownerIds': json['ownerIds'],
        'memberIds': !exists(json, 'memberIds') ? undefined : json['memberIds'],
        'status': !exists(json, 'status') ? undefined : TeamStatusV1DtoFromJSON(json['status']),
    };
}

export function TeamV1DtoToJSON(value?: TeamV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'ownerIds': Array.from(value.ownerIds as Set<any>),
        'memberIds': value.memberIds === undefined ? undefined : Array.from(value.memberIds as Set<any>),
        'status': TeamStatusV1DtoToJSON(value.status),
    };
}

