import React, { useState, useEffect } from 'react';
import { announcement } from '@text';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { NotificationAppearance } from '@maersk-global/mds-components-core/mc-notification/types';

export function AnnouncementNotificationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [showNotification, setShowNotification] = useState(true);

  useEffect(() => {
    if (!window?.localStorage) {
      return;
    }
    const isNotificationClosed = window.localStorage.getItem(
      `notification-dismissed-${announcement.id}`,
    );
    if (isNotificationClosed) {
      setShowNotification(false);
    }
  }, []);

  const handleCloseNotification = () => {
    if (!window?.localStorage) {
      return;
    }
    window.localStorage.setItem(
      `notification-dismissed-${announcement.id}`,
      'true',
    );
    setShowNotification(false);
  };

  return (
    <>
      {announcement.enabled && showNotification && (
        <div>
          <McNotification
            close={handleCloseNotification}
            appearance={announcement.type as NotificationAppearance}
            closable
            heading={announcement.header}
          >
            {announcement.message}
          </McNotification>
        </div>
      )}
      {children}
    </>
  );
}
