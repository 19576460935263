import { CustomSwrHook } from '@/interfaces/swr';
import { DefaultApi, TeamV1Dto } from '@@/generated/mtts/openapi';
import useSwr from 'swr';
import { normalizeKey } from '@/helpers/swr';

interface TransformTeamType extends TeamV1Dto {
  userIds?: string[];
}

const transformTeam = (team: TeamV1Dto): TransformTeamType => {
  const result = {
    ...team,
    userIds: [...team.ownerIds, ...(team.memberIds || [])],
  };
  return result;
};

export const useTeam = ({
  key,
  args,
  config,
  pause,
}: CustomSwrHook<DefaultApi['getTeam']> = {}) => {
  const client = useMttsApiClient();

  const params = useParams();

  const normalizedKey = normalizeKey({
    key,
    pause,
    defaultKey: ['team', args?.[0] || params.team],
  });
  const normalizedArgs = args ?? [params.team || ''];

  return useSwr(
    normalizedKey,
    () => client.getTeam(...normalizedArgs).then(transformTeam),
    config,
  );
};
