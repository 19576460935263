import AppPageHeader from '@/components/AppPageHeader/AppPageHeader';
import PageBody from '@/components/PageBody/PageBody';
import { SchemaForm } from '@/components/EventsProcessing/SchemaForm/SchemaForm';
import classes from './EventsProcessingCreateSchemaPage.module.scss';
import { SchemaV1Dto } from '@@/generated/openapi';
import useSWRMutation from 'swr/mutation';
import { waitForPipeline } from '@/helpers/waitForPipeline';

export const EventsProcessingCreateSchemaPage = () => {
  const client = useApiClient();
  const params = useParams();
  const navigate = useNavigate();
  const { trigger } = useSWRMutation(
    ['schema-create'],
    async (_key: [string], { arg: data }: { arg: SchemaV1Dto }) =>
      await waitForPipeline<SchemaV1Dto>(
        () => client.createSchemaV1(data, params.app ?? '', params.env ?? ''),
        (id) => client.getPipeline(id),
        () =>
          client.getSchemaV1(data.id ?? '', params.app ?? '', params.env ?? ''),
      ),
  );

  const onSubmit = async (data: SchemaV1Dto) => {
    try {
      const response = await trigger(data);
      console.log(response);
      if (response?.id) {
        navigate(
          `/app/${params.app}/${params.env}/events-processing/schemas/${response.id}`,
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AppPageHeader />
      <PageBody className={classes.pageBody} title="Create Schema" padding>
        <SchemaForm onSubmit={onSubmit} />
      </PageBody>
    </>
  );
};
