/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlatformResponseDto } from './PlatformResponseDto';
import {
    PlatformResponseDtoFromJSON,
    PlatformResponseDtoFromJSONTyped,
    PlatformResponseDtoToJSON,
} from './PlatformResponseDto';

/**
 * 
 * @export
 * @interface PlatformListDto
 */
export interface PlatformListDto {
    /**
     * 
     * @type {number}
     * @memberof PlatformListDto
     */
    totalRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformListDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformListDto
     */
    currentPage?: number;
    /**
     * 
     * @type {Array<PlatformResponseDto>}
     * @memberof PlatformListDto
     */
    content?: Array<PlatformResponseDto>;
}

/**
 * Check if a given object implements the PlatformListDto interface.
 */
export function instanceOfPlatformListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlatformListDtoFromJSON(json: any): PlatformListDto {
    return PlatformListDtoFromJSONTyped(json, false);
}

export function PlatformListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(PlatformResponseDtoFromJSON)),
    };
}

export function PlatformListDtoToJSON(value?: PlatformListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalRecords': value.totalRecords,
        'totalPages': value.totalPages,
        'currentPage': value.currentPage,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(PlatformResponseDtoToJSON)),
    };
}

