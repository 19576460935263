/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DuplicateDataResponseDto } from './DuplicateDataResponseDto';
import {
    DuplicateDataResponseDtoFromJSON,
    DuplicateDataResponseDtoFromJSONTyped,
    DuplicateDataResponseDtoToJSON,
} from './DuplicateDataResponseDto';

/**
 * 
 * @export
 * @interface FuzzyWuzzyResponseDto
 */
export interface FuzzyWuzzyResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof FuzzyWuzzyResponseDto
     */
    fullMatched?: boolean;
    /**
     * 
     * @type {Array<DuplicateDataResponseDto>}
     * @memberof FuzzyWuzzyResponseDto
     */
    contents?: Array<DuplicateDataResponseDto>;
}

/**
 * Check if a given object implements the FuzzyWuzzyResponseDto interface.
 */
export function instanceOfFuzzyWuzzyResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FuzzyWuzzyResponseDtoFromJSON(json: any): FuzzyWuzzyResponseDto {
    return FuzzyWuzzyResponseDtoFromJSONTyped(json, false);
}

export function FuzzyWuzzyResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuzzyWuzzyResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullMatched': !exists(json, 'fullMatched') ? undefined : json['fullMatched'],
        'contents': !exists(json, 'contents') ? undefined : ((json['contents'] as Array<any>).map(DuplicateDataResponseDtoFromJSON)),
    };
}

export function FuzzyWuzzyResponseDtoToJSON(value?: FuzzyWuzzyResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullMatched': value.fullMatched,
        'contents': value.contents === undefined ? undefined : ((value.contents as Array<any>).map(DuplicateDataResponseDtoToJSON)),
    };
}

