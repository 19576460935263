import { NotFoundMessage } from '@/components/NotFoundMessage/NotFoundMessage';
import classes from './NotFound.module.css';

const NotFound = () => {
  return (
    <div className={classes.cont}>
      <NotFoundMessage />
    </div>
  );
};

export default NotFound;
