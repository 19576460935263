import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import Select from '@/components/Select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McModal } from '@maersk-global/mds-react-wrapper/components-core/mc-modal';
import { appPage, quickstartAppModal } from '@text';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import * as yup from 'yup';
import useMttsApiClient from '../../../hooks/useMttsApiClient';
import useSWRMutation from 'swr/mutation';
import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper/components-core/mc-loading-indicator';
import { useTeamOptions } from '@/hooks/useTeamOptions';
import { useUserIsAppOwner } from '@/hooks/useUserIsAppOwner';

const schema = yup.object({
  team: yup.string().required('Team is required'),
});

export const TeamChangeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { app } = useParams();
  const client = useMttsApiClient();
  const teamOptions = useTeamOptions();
  const userInAppTeam = useUserIsAppOwner();

  const { data: mttsApp, isLoading: isLoadingMttsApp } = useSWR(
    ['mtts-app', app],
    () => client.getApplication(app ?? ''),
    {
      refreshInterval: 0,
    },
  );

  const form = useForm({
    // @ts-expect-error yup resolver is not typed correctly
    resolver: yupResolver(schema),
    defaultValues: {
      team: mttsApp?.teamId,
    },
  });

  // Update form values when mttsApp changes
  useEffect(() => {
    if (mttsApp?.teamId) {
      form.reset({ team: mttsApp.teamId });
    }
  }, [mttsApp, form]);

  const { mutate: mutateControlplaneApp } = useSWR(['app', app]);

  const { trigger: updateApp, isMutating: isUpdating } = useSWRMutation(
    ['mtts-app', app],
    () => {
      const team = form.getValues('team');
      if (!mttsApp || !team) return;
      return client.updateApplication(
        {
          ...mttsApp,
          teamId: team,
        },
        app ?? '',
      );
    },
  );

  const onSubmit = async () => {
    await updateApp();
    await mutateControlplaneApp();
    setIsOpen(false);
  };

  const handleClose = () => {
    form.reset({ team: mttsApp?.teamId });
    setIsOpen(false);
  };

  return (
    <>
      <McButton
        variant="plain"
        hiddenlabel
        icon="pencil"
        padding="none"
        appearance="primary"
        fit="small"
        disabled={!userInAppTeam}
        onClick={() => setIsOpen(true)}
      />
      <FormWrapper form={form} schema={schema}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <McModal
            open={isOpen}
            opened={() => setIsOpen(true)}
            closed={handleClose}
            fit="small"
            width="400px"
            heading={appPage.teamChange.replace(
              '{{appName}}',
              mttsApp?.id ?? '',
            )}
          >
            {isLoadingMttsApp ? (
              <McLoadingIndicator />
            ) : (
              <Select
                name={'team'}
                text={quickstartAppModal['applicationTemplate.teamRef.name']}
                valueOnly
                options={teamOptions}
                data-testid={'team'}
              />
            )}
            <McButton
              slot="secondaryAction"
              variant="outlined"
              appearance="primary"
              onClick={handleClose}
              type="button"
            >
              Cancel
            </McButton>
            <McButton type="submit" slot="primaryAction" loading={isUpdating}>
              Change team
            </McButton>
          </McModal>
        </form>
      </FormWrapper>
    </>
  );
};
