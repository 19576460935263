/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationMetaResponseDto } from './ApplicationMetaResponseDto';
import {
    ApplicationMetaResponseDtoFromJSON,
    ApplicationMetaResponseDtoFromJSONTyped,
    ApplicationMetaResponseDtoToJSON,
} from './ApplicationMetaResponseDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PlatformMetaResponseDto } from './PlatformMetaResponseDto';
import {
    PlatformMetaResponseDtoFromJSON,
    PlatformMetaResponseDtoFromJSONTyped,
    PlatformMetaResponseDtoToJSON,
} from './PlatformMetaResponseDto';

/**
 * 
 * @export
 * @interface ProductResponseDto
 */
export interface ProductResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    status?: ProductResponseDtoStatusEnum;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ProductResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {PlatformMetaResponseDto}
     * @memberof ProductResponseDto
     */
    platform?: PlatformMetaResponseDto;
    /**
     * 
     * @type {Set<ApplicationMetaResponseDto>}
     * @memberof ProductResponseDto
     */
    applications?: Set<ApplicationMetaResponseDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponseDto
     */
    canEdit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseDto
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductResponseDto
     */
    modifiedDate?: Date;
}


/**
 * @export
 */
export const ProductResponseDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Deleted: 'Deleted'
} as const;
export type ProductResponseDtoStatusEnum = typeof ProductResponseDtoStatusEnum[keyof typeof ProductResponseDtoStatusEnum];


/**
 * Check if a given object implements the ProductResponseDto interface.
 */
export function instanceOfProductResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductResponseDtoFromJSON(json: any): ProductResponseDto {
    return ProductResponseDtoFromJSONTyped(json, false);
}

export function ProductResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'productCode': !exists(json, 'productCode') ? undefined : json['productCode'],
        'productType': !exists(json, 'productType') ? undefined : json['productType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'platform': !exists(json, 'platform') ? undefined : PlatformMetaResponseDtoFromJSON(json['platform']),
        'applications': !exists(json, 'applications') ? undefined : (new Set((json['applications'] as Array<any>).map(ApplicationMetaResponseDtoFromJSON))),
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
    };
}

export function ProductResponseDtoToJSON(value?: ProductResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'productName': value.productName,
        'productCode': value.productCode,
        'productType': value.productType,
        'description': value.description,
        'status': value.status,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'platform': PlatformMetaResponseDtoToJSON(value.platform),
        'applications': value.applications === undefined ? undefined : (Array.from(value.applications as Set<any>).map(ApplicationMetaResponseDtoToJSON)),
        'canEdit': value.canEdit,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
    };
}

