import {
  PageDetailsBody,
  PageDetailsColumn,
} from '@/components/PageHeader/PageHeader';
import { PageDetailsHead } from '@/components/PageHeader/PageHeader';
import { PageDetailsRow } from '@/components/PageHeader/PageHeader';
import { PageDetails } from '@/components/PageHeader/PageHeader';
import { PageName } from '@/components/PageHeader/PageHeader';
import PageHeader from '@/components/PageHeader/PageHeader';
import { getAppUrl } from '@/helpers/urls';
import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper/components-core/mc-loading-indicator';
import useSWR from 'swr';
import classes from './EventProcessingSchemaPage.module.scss';
import PageBody from '@/components/PageBody/PageBody';

export const EventProcessingSchemaPage = () => {
  const { app, env, schema } = useParams();
  const client = useApiClient();
  const { data: schemaDetails, isLoading } = useSWR(
    ['schemas', app, env, schema],
    () => client.getSchemaV1(app ?? '', env ?? '', schema ?? ''),
  );

  if (isLoading) {
    return <McLoadingIndicator variant="bar" />;
  }
  console.log(schemaDetails);

  const formattedValueSchema = JSON.stringify(
    JSON.parse(schemaDetails?.content ?? '{}'),
    null,
    2,
  );

  return (
    <>
      <PageHeader>
        <PageName>
          <span>{schemaDetails?.name}</span>
        </PageName>
        <PageDetails>
          <PageDetailsRow>
            <PageDetailsColumn>
              <PageDetailsHead>App</PageDetailsHead>
              <PageDetailsBody>
                <Link to={getAppUrl({ app, env })}>{app}</Link>
              </PageDetailsBody>
            </PageDetailsColumn>
            <PageDetailsColumn>
              <PageDetailsHead>Environment</PageDetailsHead>
              <PageDetailsBody>{env}</PageDetailsBody>
            </PageDetailsColumn>
            <PageDetailsColumn>
              <PageDetailsHead>Schema type</PageDetailsHead>
              <PageDetailsBody>{schemaDetails?.type}</PageDetailsBody>
            </PageDetailsColumn>
          </PageDetailsRow>
        </PageDetails>
      </PageHeader>
      <PageBody className={classes.pageBody} title="Schema" padding>
        <pre className={classes.code}>{formattedValueSchema}</pre>
      </PageBody>
    </>
  );
};
