/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationIssueRequestDto,
  ApplicationListDto,
  ApplicationRequestDto,
  ApplicationResponseDto,
  DashboardCountResponseDto,
  DataQualityListDto,
  FuzzyWuzzyResponseDto,
  OwnerRequestDto,
  PatchApplicationRequestDto,
  PlatformListDto,
  PlatformRequestDto,
  PlatformResponseDto,
  PortfolioListDto,
  PortfolioRequestDto,
  PortfolioResponseDto,
  ProductApplicationAssociationRequestDto,
  ProductListDto,
  ProductRequestDto,
  ProductResponseDto,
  UpdateAppPlatformRequestDto,
  UpdateApplicationRequestDto,
  UpdatePlatformRequestDto,
  UpdatePortfolioRequestDto,
  UpdateProductRequestDto,
} from '../models/index';
import {
    ApplicationIssueRequestDtoFromJSON,
    ApplicationIssueRequestDtoToJSON,
    ApplicationListDtoFromJSON,
    ApplicationListDtoToJSON,
    ApplicationRequestDtoFromJSON,
    ApplicationRequestDtoToJSON,
    ApplicationResponseDtoFromJSON,
    ApplicationResponseDtoToJSON,
    DashboardCountResponseDtoFromJSON,
    DashboardCountResponseDtoToJSON,
    DataQualityListDtoFromJSON,
    DataQualityListDtoToJSON,
    FuzzyWuzzyResponseDtoFromJSON,
    FuzzyWuzzyResponseDtoToJSON,
    OwnerRequestDtoFromJSON,
    OwnerRequestDtoToJSON,
    PatchApplicationRequestDtoFromJSON,
    PatchApplicationRequestDtoToJSON,
    PlatformListDtoFromJSON,
    PlatformListDtoToJSON,
    PlatformRequestDtoFromJSON,
    PlatformRequestDtoToJSON,
    PlatformResponseDtoFromJSON,
    PlatformResponseDtoToJSON,
    PortfolioListDtoFromJSON,
    PortfolioListDtoToJSON,
    PortfolioRequestDtoFromJSON,
    PortfolioRequestDtoToJSON,
    PortfolioResponseDtoFromJSON,
    PortfolioResponseDtoToJSON,
    ProductApplicationAssociationRequestDtoFromJSON,
    ProductApplicationAssociationRequestDtoToJSON,
    ProductListDtoFromJSON,
    ProductListDtoToJSON,
    ProductRequestDtoFromJSON,
    ProductRequestDtoToJSON,
    ProductResponseDtoFromJSON,
    ProductResponseDtoToJSON,
    UpdateAppPlatformRequestDtoFromJSON,
    UpdateAppPlatformRequestDtoToJSON,
    UpdateApplicationRequestDtoFromJSON,
    UpdateApplicationRequestDtoToJSON,
    UpdatePlatformRequestDtoFromJSON,
    UpdatePlatformRequestDtoToJSON,
    UpdatePortfolioRequestDtoFromJSON,
    UpdatePortfolioRequestDtoToJSON,
    UpdateProductRequestDtoFromJSON,
    UpdateProductRequestDtoToJSON,
} from '../models/index';

export interface AssignApplicationsRequest {
    productApplicationAssociationRequestDto: ProductApplicationAssociationRequestDto;
    productId: string;
    source: string;
    macProductId: string;
}

export interface CreateRequest {
    productRequestDto: ProductRequestDto;
}

export interface DashboardMatricesRequest {
    entity: string;
    page?: number;
    size?: number;
    errorInfo?: boolean;
    portfolioId?: string;
}

export interface DecommissionApplicationRequest {
    appId: string;
    macAppId: string;
}

export interface DecommissionPlatformRequest {
    platformId: string;
}

export interface DecommissionPortfolioRequest {
    portfolioId: string;
}

export interface FetchRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    id?: string;
    name?: string;
}

export interface FuzzySearchRequest {
    entity: string;
    searchTerm: string;
}

export interface GetApplicationByIdRequest {
    appId: string;
    requestId?: string;
    requestType?: string;
    includeRequest?: boolean;
}

export interface GetApplicationsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    name?: string;
}

export interface GetCodeDetailsRequest {
    code: string;
    platformId?: string;
}

export interface GetDashboardCountRequest {
    days?: number;
}

export interface GetPlatformByIdRequest {
    platformId: string;
}

export interface GetPlatformsRequest {
    page?: number;
    size?: number;
    name?: string;
    active?: boolean;
    oid?: string;
}

export interface GetPortfolioByIdRequest {
    portfolioId: string;
}

export interface GetPortfolioPlatformsByIdRequest {
    portfolioId: string;
    xContentType: string;
}

export interface GetPortfoliosRequest {
    page?: number;
    size?: number;
    name?: string;
    active?: boolean;
}

export interface GetProductApplicationsRequest {
    productId: string;
    page?: number;
    size?: number;
}

export interface GetProductsByPlatformIdRequest {
    platformId: string;
}

export interface OnBoardApplicationRequest {
    applicationRequestDto: ApplicationRequestDto;
}

export interface OnBoardPlatformRequest {
    platformRequestDto: PlatformRequestDto;
}

export interface OnBoardPortfolioRequest {
    portfolioRequestDto: PortfolioRequestDto;
}

export interface PlatformSearchRequest {
    platform: string;
    portfolio?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ProcessOwnerRequestRequest {
    ownerRequestDto: OwnerRequestDto;
    appId: string;
    macAppId: string;
    requestId?: string;
}

export interface ReportApplicationDataIssueRequest {
    applicationIssueRequestDto: ApplicationIssueRequestDto;
    appId: string;
}

export interface SearchApplicationsRequest {
    app?: string;
    platform?: string;
    portfolio?: string;
    status?: string;
    fromTargetDecomDate?: Date;
    toTargetDecomDate?: Date;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpdateRequest {
    updateProductRequestDto: UpdateProductRequestDto;
    productId: string;
    macProductId: string;
}

export interface UpdateApplicationRequest {
    updateApplicationRequestDto: UpdateApplicationRequestDto;
    appId: string;
    macAppId: string;
    macRequestId?: string;
}

export interface UpdateApplication1Request {
    patchApplicationRequestDto: PatchApplicationRequestDto;
    appId: string;
    action: string;
    macAppId: string;
}

export interface UpdateApplicationPlatformRequest {
    updateAppPlatformRequestDto: UpdateAppPlatformRequestDto;
    appId: string;
    requestId: string;
    macAppId: string;
}

export interface UpdateApplicationsRequest {
    patchApplicationRequestDto: PatchApplicationRequestDto;
    appId: string;
    macAppId: string;
}

export interface UpdatePlatformRequest {
    updatePlatformRequestDto: UpdatePlatformRequestDto;
    platformId: string;
    macPlatformId: string;
}

export interface UpdatePortfolioRequest {
    updatePortfolioRequestDto: UpdatePortfolioRequestDto;
    portfolioId: string;
    macPortfolioId: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Associate existing  applications to a product
     * Associate existing applications to a product
     */
    async assignApplicationsRaw(requestParameters: AssignApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductResponseDto>> {
        if (requestParameters.productApplicationAssociationRequestDto === null || requestParameters.productApplicationAssociationRequestDto === undefined) {
            throw new runtime.RequiredError('productApplicationAssociationRequestDto','Required parameter requestParameters.productApplicationAssociationRequestDto was null or undefined when calling assignApplications.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling assignApplications.');
        }

        if (requestParameters.source === null || requestParameters.source === undefined) {
            throw new runtime.RequiredError('source','Required parameter requestParameters.source was null or undefined when calling assignApplications.');
        }

        if (requestParameters.macProductId === null || requestParameters.macProductId === undefined) {
            throw new runtime.RequiredError('macProductId','Required parameter requestParameters.macProductId was null or undefined when calling assignApplications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.source !== undefined && requestParameters.source !== null) {
            headerParameters['source'] = String(requestParameters.source);
        }

        if (requestParameters.macProductId !== undefined && requestParameters.macProductId !== null) {
            headerParameters['Mac-Product-Id'] = String(requestParameters.macProductId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{productId}/applications`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProductApplicationAssociationRequestDtoToJSON(requestParameters.productApplicationAssociationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductResponseDtoFromJSON(jsonValue));
    }

    /**
     * Associate existing  applications to a product
     * Associate existing applications to a product
     */
    async assignApplications(productApplicationAssociationRequestDto: ProductApplicationAssociationRequestDto, productId: string, source: string, macProductId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductResponseDto> {
        const response = await this.assignApplicationsRaw({ productApplicationAssociationRequestDto: productApplicationAssociationRequestDto, productId: productId, source: source, macProductId: macProductId }, initOverrides);
        return await response.value();
    }

    /**
     * Onboard a Product
     * Onboard a product
     */
    async createRaw(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductResponseDto>> {
        if (requestParameters.productRequestDto === null || requestParameters.productRequestDto === undefined) {
            throw new runtime.RequiredError('productRequestDto','Required parameter requestParameters.productRequestDto was null or undefined when calling create.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductRequestDtoToJSON(requestParameters.productRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductResponseDtoFromJSON(jsonValue));
    }

    /**
     * Onboard a Product
     * Onboard a product
     */
    async create(productRequestDto: ProductRequestDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductResponseDto> {
        const response = await this.createRaw({ productRequestDto: productRequestDto }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve dashboard matrices by platform or portfolio
     * Retrieve dashboard matrices by platform or portfolio
     */
    async dashboardMatricesRaw(requestParameters: DashboardMatricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataQualityListDto>> {
        if (requestParameters.entity === null || requestParameters.entity === undefined) {
            throw new runtime.RequiredError('entity','Required parameter requestParameters.entity was null or undefined when calling dashboardMatrices.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.errorInfo !== undefined) {
            queryParameters['errorInfo'] = requestParameters.errorInfo;
        }

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dashboard/{entity}/matrices`.replace(`{${"entity"}}`, encodeURIComponent(String(requestParameters.entity))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataQualityListDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve dashboard matrices by platform or portfolio
     * Retrieve dashboard matrices by platform or portfolio
     */
    async dashboardMatrices(entity: string, page?: number, size?: number, errorInfo?: boolean, portfolioId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataQualityListDto> {
        const response = await this.dashboardMatricesRaw({ entity: entity, page: page, size: size, errorInfo: errorInfo, portfolioId: portfolioId }, initOverrides);
        return await response.value();
    }

    /**
     * Decommission an application
     * Decommission an application
     */
    async decommissionApplicationRaw(requestParameters: DecommissionApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling decommissionApplication.');
        }

        if (requestParameters.macAppId === null || requestParameters.macAppId === undefined) {
            throw new runtime.RequiredError('macAppId','Required parameter requestParameters.macAppId was null or undefined when calling decommissionApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.macAppId !== undefined && requestParameters.macAppId !== null) {
            headerParameters['Mac-App-Id'] = String(requestParameters.macAppId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decommission an application
     * Decommission an application
     */
    async decommissionApplication(appId: string, macAppId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.decommissionApplicationRaw({ appId: appId, macAppId: macAppId }, initOverrides);
    }

    /**
     * Decommission a platform
     * Decommission a platform
     */
    async decommissionPlatformRaw(requestParameters: DecommissionPlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
            throw new runtime.RequiredError('platformId','Required parameter requestParameters.platformId was null or undefined when calling decommissionPlatform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/platforms/{platformId}`.replace(`{${"platformId"}}`, encodeURIComponent(String(requestParameters.platformId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decommission a platform
     * Decommission a platform
     */
    async decommissionPlatform(platformId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.decommissionPlatformRaw({ platformId: platformId }, initOverrides);
    }

    /**
     * Decommission a portfolio
     * Decommission a portfolio
     */
    async decommissionPortfolioRaw(requestParameters: DecommissionPortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling decommissionPortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portfolios/{portfolioId}`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decommission a portfolio
     * Decommission a portfolio
     */
    async decommissionPortfolio(portfolioId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.decommissionPortfolioRaw({ portfolioId: portfolioId }, initOverrides);
    }

    /**
     * Retrieve all Products onboarded in MAC.
     * Retrieve all Products
     */
    async fetchRaw(requestParameters: FetchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductListDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve all Products onboarded in MAC.
     * Retrieve all Products
     */
    async fetch(page?: number, size?: number, sort?: Array<string>, id?: string, name?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductListDto> {
        const response = await this.fetchRaw({ page: page, size: size, sort: sort, id: id, name: name }, initOverrides);
        return await response.value();
    }

    /**
     * Matches all possible duplicate applications/platforms which is existing in MAC using FuzzyWuzzy logic.
     * Fuzzy search of application/platform by search term text.
     */
    async fuzzySearchRaw(requestParameters: FuzzySearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FuzzyWuzzyResponseDto>> {
        if (requestParameters.entity === null || requestParameters.entity === undefined) {
            throw new runtime.RequiredError('entity','Required parameter requestParameters.entity was null or undefined when calling fuzzySearch.');
        }

        if (requestParameters.searchTerm === null || requestParameters.searchTerm === undefined) {
            throw new runtime.RequiredError('searchTerm','Required parameter requestParameters.searchTerm was null or undefined when calling fuzzySearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.entity !== undefined) {
            queryParameters['entity'] = requestParameters.entity;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search/fuzzy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuzzyWuzzyResponseDtoFromJSON(jsonValue));
    }

    /**
     * Matches all possible duplicate applications/platforms which is existing in MAC using FuzzyWuzzy logic.
     * Fuzzy search of application/platform by search term text.
     */
    async fuzzySearch(entity: string, searchTerm: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FuzzyWuzzyResponseDto> {
        const response = await this.fuzzySearchRaw({ entity: entity, searchTerm: searchTerm }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve an application onboarded in MAC
     * Find an application
     */
    async getApplicationByIdRaw(requestParameters: GetApplicationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationResponseDto>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling getApplicationById.');
        }

        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['requestId'] = requestParameters.requestId;
        }

        if (requestParameters.requestType !== undefined) {
            queryParameters['requestType'] = requestParameters.requestType;
        }

        if (requestParameters.includeRequest !== undefined) {
            queryParameters['includeRequest'] = requestParameters.includeRequest;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve an application onboarded in MAC
     * Find an application
     */
    async getApplicationById(appId: string, requestId?: string, requestType?: string, includeRequest?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationResponseDto> {
        const response = await this.getApplicationByIdRaw({ appId: appId, requestId: requestId, requestType: requestType, includeRequest: includeRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all applications onboarded in MAC.
     * Retrieve all applications
     */
    async getApplicationsRaw(requestParameters: GetApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationListDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationListDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve all applications onboarded in MAC.
     * Retrieve all applications
     */
    async getApplications(page?: number, size?: number, sort?: Array<string>, name?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationListDto> {
        const response = await this.getApplicationsRaw({ page: page, size: size, sort: sort, name: name }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve dashboard detailed information based on code.
     * Retrieve dashboard detailed information based on code.
     */
    async getCodeDetailsRaw(requestParameters: GetCodeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardCountResponseDto>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getCodeDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.platformId !== undefined) {
            queryParameters['platformId'] = requestParameters.platformId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardCountResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve dashboard detailed information based on code.
     * Retrieve dashboard detailed information based on code.
     */
    async getCodeDetails(code: string, platformId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardCountResponseDto> {
        const response = await this.getCodeDetailsRaw({ code: code, platformId: platformId }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all Portfolio, Platform & Application count with Active, Inactive & Total count. If days provided then it will return application onboarded in given last days from now.
     * Retrieve dashboard information or application onboarded count in given days
     */
    async getDashboardCountRaw(requestParameters: GetDashboardCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardCountResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters.days !== undefined) {
            queryParameters['days'] = requestParameters.days;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dashboard/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardCountResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve all Portfolio, Platform & Application count with Active, Inactive & Total count. If days provided then it will return application onboarded in given last days from now.
     * Retrieve dashboard information or application onboarded count in given days
     */
    async getDashboardCount(days?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardCountResponseDto> {
        const response = await this.getDashboardCountRaw({ days: days }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a platform onboarded in MAC
     * Find a platform
     */
    async getPlatformByIdRaw(requestParameters: GetPlatformByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlatformResponseDto>> {
        if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
            throw new runtime.RequiredError('platformId','Required parameter requestParameters.platformId was null or undefined when calling getPlatformById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/platforms/{platformId}`.replace(`{${"platformId"}}`, encodeURIComponent(String(requestParameters.platformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlatformResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve a platform onboarded in MAC
     * Find a platform
     */
    async getPlatformById(platformId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlatformResponseDto> {
        const response = await this.getPlatformByIdRaw({ platformId: platformId }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all platforms or by name of platform.
     * Retrieve all platforms or by name
     */
    async getPlatformsRaw(requestParameters: GetPlatformsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlatformListDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.oid !== undefined) {
            queryParameters['oid'] = requestParameters.oid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/platforms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlatformListDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve all platforms or by name of platform.
     * Retrieve all platforms or by name
     */
    async getPlatforms(page?: number, size?: number, name?: string, active?: boolean, oid?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlatformListDto> {
        const response = await this.getPlatformsRaw({ page: page, size: size, name: name, active: active, oid: oid }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a portfolio onboarded in MAC
     * Find a portfolio
     */
    async getPortfolioByIdRaw(requestParameters: GetPortfolioByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioResponseDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling getPortfolioById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portfolios/{portfolioId}`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve a portfolio onboarded in MAC
     * Find a portfolio
     */
    async getPortfolioById(portfolioId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioResponseDto> {
        const response = await this.getPortfolioByIdRaw({ portfolioId: portfolioId }, initOverrides);
        return await response.value();
    }

    /**
     * Find a portfolio information along with all associated platforms available in system.
     * Find a portfolio information along with all associated platforms
     */
    async getPortfolioPlatformsByIdRaw(requestParameters: GetPortfolioPlatformsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioResponseDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling getPortfolioPlatformsById.');
        }

        if (requestParameters.xContentType === null || requestParameters.xContentType === undefined) {
            throw new runtime.RequiredError('xContentType','Required parameter requestParameters.xContentType was null or undefined when calling getPortfolioPlatformsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xContentType !== undefined && requestParameters.xContentType !== null) {
            headerParameters['X-Content-Type'] = String(requestParameters.xContentType);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portfolios/{portfolioId}/platforms`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioResponseDtoFromJSON(jsonValue));
    }

    /**
     * Find a portfolio information along with all associated platforms available in system.
     * Find a portfolio information along with all associated platforms
     */
    async getPortfolioPlatformsById(portfolioId: string, xContentType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioResponseDto> {
        const response = await this.getPortfolioPlatformsByIdRaw({ portfolioId: portfolioId, xContentType: xContentType }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all portfolios onboarded in MAC or by name
     * Retrieve all portfolios or by name
     */
    async getPortfoliosRaw(requestParameters: GetPortfoliosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioListDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portfolios`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioListDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve all portfolios onboarded in MAC or by name
     * Retrieve all portfolios or by name
     */
    async getPortfolios(page?: number, size?: number, name?: string, active?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioListDto> {
        const response = await this.getPortfoliosRaw({ page: page, size: size, name: name, active: active }, initOverrides);
        return await response.value();
    }

    /**
     * Add applications to a product
     * Get applications to a product
     */
    async getProductApplicationsRaw(requestParameters: GetProductApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductResponseDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductApplications.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{productId}/applications`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductResponseDtoFromJSON(jsonValue));
    }

    /**
     * Add applications to a product
     * Get applications to a product
     */
    async getProductApplications(productId: string, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductResponseDto> {
        const response = await this.getProductApplicationsRaw({ productId: productId, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Find a platform along with products and optional with applications
     * Find a platform along with products
     */
    async getProductsByPlatformIdRaw(requestParameters: GetProductsByPlatformIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlatformResponseDto>> {
        if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
            throw new runtime.RequiredError('platformId','Required parameter requestParameters.platformId was null or undefined when calling getProductsByPlatformId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/platforms/{platformId}/products`.replace(`{${"platformId"}}`, encodeURIComponent(String(requestParameters.platformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlatformResponseDtoFromJSON(jsonValue));
    }

    /**
     * Find a platform along with products and optional with applications
     * Find a platform along with products
     */
    async getProductsByPlatformId(platformId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlatformResponseDto> {
        const response = await this.getProductsByPlatformIdRaw({ platformId: platformId }, initOverrides);
        return await response.value();
    }

    /**
     * Onboard an application
     * Onboard an application
     */
    async onBoardApplicationRaw(requestParameters: OnBoardApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationResponseDto>> {
        if (requestParameters.applicationRequestDto === null || requestParameters.applicationRequestDto === undefined) {
            throw new runtime.RequiredError('applicationRequestDto','Required parameter requestParameters.applicationRequestDto was null or undefined when calling onBoardApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationRequestDtoToJSON(requestParameters.applicationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Onboard an application
     * Onboard an application
     */
    async onBoardApplication(applicationRequestDto: ApplicationRequestDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationResponseDto> {
        const response = await this.onBoardApplicationRaw({ applicationRequestDto: applicationRequestDto }, initOverrides);
        return await response.value();
    }

    /**
     * Onboard a platform
     * Onboard a platform
     */
    async onBoardPlatformRaw(requestParameters: OnBoardPlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlatformResponseDto>> {
        if (requestParameters.platformRequestDto === null || requestParameters.platformRequestDto === undefined) {
            throw new runtime.RequiredError('platformRequestDto','Required parameter requestParameters.platformRequestDto was null or undefined when calling onBoardPlatform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/platforms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlatformRequestDtoToJSON(requestParameters.platformRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlatformResponseDtoFromJSON(jsonValue));
    }

    /**
     * Onboard a platform
     * Onboard a platform
     */
    async onBoardPlatform(platformRequestDto: PlatformRequestDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlatformResponseDto> {
        const response = await this.onBoardPlatformRaw({ platformRequestDto: platformRequestDto }, initOverrides);
        return await response.value();
    }

    /**
     * Onboard a portfolio
     * Onboard a portfolio
     */
    async onBoardPortfolioRaw(requestParameters: OnBoardPortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioResponseDto>> {
        if (requestParameters.portfolioRequestDto === null || requestParameters.portfolioRequestDto === undefined) {
            throw new runtime.RequiredError('portfolioRequestDto','Required parameter requestParameters.portfolioRequestDto was null or undefined when calling onBoardPortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portfolios`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioRequestDtoToJSON(requestParameters.portfolioRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioResponseDtoFromJSON(jsonValue));
    }

    /**
     * Onboard a portfolio
     * Onboard a portfolio
     */
    async onBoardPortfolio(portfolioRequestDto: PortfolioRequestDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioResponseDto> {
        const response = await this.onBoardPortfolioRaw({ portfolioRequestDto: portfolioRequestDto }, initOverrides);
        return await response.value();
    }

    /**
     * Search Platform by name or id or platform owner or portfolio name or id. Also by combination of all these fields.
     * Search Platform by name or id or platform owner or portfolio name or id. Also by combination of all these fields.
     */
    async platformSearchRaw(requestParameters: PlatformSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlatformListDto>> {
        if (requestParameters.platform === null || requestParameters.platform === undefined) {
            throw new runtime.RequiredError('platform','Required parameter requestParameters.platform was null or undefined when calling platformSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.platform !== undefined) {
            queryParameters['platform'] = requestParameters.platform;
        }

        if (requestParameters.portfolio !== undefined) {
            queryParameters['portfolio'] = requestParameters.portfolio;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search/platforms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlatformListDtoFromJSON(jsonValue));
    }

    /**
     * Search Platform by name or id or platform owner or portfolio name or id. Also by combination of all these fields.
     * Search Platform by name or id or platform owner or portfolio name or id. Also by combination of all these fields.
     */
    async platformSearch(platform: string, portfolio?: string, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlatformListDto> {
        const response = await this.platformSearchRaw({ platform: platform, portfolio: portfolio, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Application Owner Addition Request
     * Application Owner Addition Request
     */
    async processOwnerRequestRaw(requestParameters: ProcessOwnerRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ownerRequestDto === null || requestParameters.ownerRequestDto === undefined) {
            throw new runtime.RequiredError('ownerRequestDto','Required parameter requestParameters.ownerRequestDto was null or undefined when calling processOwnerRequest.');
        }

        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling processOwnerRequest.');
        }

        if (requestParameters.macAppId === null || requestParameters.macAppId === undefined) {
            throw new runtime.RequiredError('macAppId','Required parameter requestParameters.macAppId was null or undefined when calling processOwnerRequest.');
        }

        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['requestId'] = requestParameters.requestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macAppId !== undefined && requestParameters.macAppId !== null) {
            headerParameters['Mac-App-Id'] = String(requestParameters.macAppId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}/owners`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OwnerRequestDtoToJSON(requestParameters.ownerRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Application Owner Addition Request
     * Application Owner Addition Request
     */
    async processOwnerRequest(ownerRequestDto: OwnerRequestDto, appId: string, macAppId: string, requestId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.processOwnerRequestRaw({ ownerRequestDto: ownerRequestDto, appId: appId, macAppId: macAppId, requestId: requestId }, initOverrides);
    }

    /**
     * Report for application data issue
     * Report for application data issue
     */
    async reportApplicationDataIssueRaw(requestParameters: ReportApplicationDataIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationIssueRequestDto === null || requestParameters.applicationIssueRequestDto === undefined) {
            throw new runtime.RequiredError('applicationIssueRequestDto','Required parameter requestParameters.applicationIssueRequestDto was null or undefined when calling reportApplicationDataIssue.');
        }

        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling reportApplicationDataIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}/issue`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationIssueRequestDtoToJSON(requestParameters.applicationIssueRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Report for application data issue
     * Report for application data issue
     */
    async reportApplicationDataIssue(applicationIssueRequestDto: ApplicationIssueRequestDto, appId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportApplicationDataIssueRaw({ applicationIssueRequestDto: applicationIssueRequestDto, appId: appId }, initOverrides);
    }

    /**
     * Search applications by name or id or by platform name or id or portfolio name or id. Also by combination of all these fields.
     * Search applications by name or id or by platform name or id or portfolio name or id. Also by combination of all these fields.
     */
    async searchApplicationsRaw(requestParameters: SearchApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationListDto>> {
        const queryParameters: any = {};

        if (requestParameters.app !== undefined) {
            queryParameters['app'] = requestParameters.app;
        }

        if (requestParameters.platform !== undefined) {
            queryParameters['platform'] = requestParameters.platform;
        }

        if (requestParameters.portfolio !== undefined) {
            queryParameters['portfolio'] = requestParameters.portfolio;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.fromTargetDecomDate !== undefined) {
            queryParameters['fromTargetDecomDate'] = (requestParameters.fromTargetDecomDate as any).toISOString().substring(0,10);
        }

        if (requestParameters.toTargetDecomDate !== undefined) {
            queryParameters['toTargetDecomDate'] = (requestParameters.toTargetDecomDate as any).toISOString().substring(0,10);
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationListDtoFromJSON(jsonValue));
    }

    /**
     * Search applications by name or id or by platform name or id or portfolio name or id. Also by combination of all these fields.
     * Search applications by name or id or by platform name or id or portfolio name or id. Also by combination of all these fields.
     */
    async searchApplications(app?: string, platform?: string, portfolio?: string, status?: string, fromTargetDecomDate?: Date, toTargetDecomDate?: Date, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationListDto> {
        const response = await this.searchApplicationsRaw({ app: app, platform: platform, portfolio: portfolio, status: status, fromTargetDecomDate: fromTargetDecomDate, toTargetDecomDate: toTargetDecomDate, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Onboard an application
     * Update a product
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductResponseDto>> {
        if (requestParameters.updateProductRequestDto === null || requestParameters.updateProductRequestDto === undefined) {
            throw new runtime.RequiredError('updateProductRequestDto','Required parameter requestParameters.updateProductRequestDto was null or undefined when calling update.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling update.');
        }

        if (requestParameters.macProductId === null || requestParameters.macProductId === undefined) {
            throw new runtime.RequiredError('macProductId','Required parameter requestParameters.macProductId was null or undefined when calling update.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macProductId !== undefined && requestParameters.macProductId !== null) {
            headerParameters['Mac-Product-Id'] = String(requestParameters.macProductId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProductRequestDtoToJSON(requestParameters.updateProductRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductResponseDtoFromJSON(jsonValue));
    }

    /**
     * Onboard an application
     * Update a product
     */
    async update(updateProductRequestDto: UpdateProductRequestDto, productId: string, macProductId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductResponseDto> {
        const response = await this.updateRaw({ updateProductRequestDto: updateProductRequestDto, productId: productId, macProductId: macProductId }, initOverrides);
        return await response.value();
    }

    /**
     * Update application an application
     * Update Application
     */
    async updateApplicationRaw(requestParameters: UpdateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationResponseDto>> {
        if (requestParameters.updateApplicationRequestDto === null || requestParameters.updateApplicationRequestDto === undefined) {
            throw new runtime.RequiredError('updateApplicationRequestDto','Required parameter requestParameters.updateApplicationRequestDto was null or undefined when calling updateApplication.');
        }

        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling updateApplication.');
        }

        if (requestParameters.macAppId === null || requestParameters.macAppId === undefined) {
            throw new runtime.RequiredError('macAppId','Required parameter requestParameters.macAppId was null or undefined when calling updateApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macAppId !== undefined && requestParameters.macAppId !== null) {
            headerParameters['Mac-App-Id'] = String(requestParameters.macAppId);
        }

        if (requestParameters.macRequestId !== undefined && requestParameters.macRequestId !== null) {
            headerParameters['Mac-Request-Id'] = String(requestParameters.macRequestId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateApplicationRequestDtoToJSON(requestParameters.updateApplicationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Update application an application
     * Update Application
     */
    async updateApplication(updateApplicationRequestDto: UpdateApplicationRequestDto, appId: string, macAppId: string, macRequestId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationResponseDto> {
        const response = await this.updateApplicationRaw({ updateApplicationRequestDto: updateApplicationRequestDto, appId: appId, macAppId: macAppId, macRequestId: macRequestId }, initOverrides);
        return await response.value();
    }

    /**
     * This operation allows partial updates to an application\'s data. Only the fields provided in the request will be updated, leaving the rest of the application\'s data unchanged.
     * Updates only necessary field for Application
     */
    async updateApplication1Raw(requestParameters: UpdateApplication1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationResponseDto>> {
        if (requestParameters.patchApplicationRequestDto === null || requestParameters.patchApplicationRequestDto === undefined) {
            throw new runtime.RequiredError('patchApplicationRequestDto','Required parameter requestParameters.patchApplicationRequestDto was null or undefined when calling updateApplication1.');
        }

        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling updateApplication1.');
        }

        if (requestParameters.action === null || requestParameters.action === undefined) {
            throw new runtime.RequiredError('action','Required parameter requestParameters.action was null or undefined when calling updateApplication1.');
        }

        if (requestParameters.macAppId === null || requestParameters.macAppId === undefined) {
            throw new runtime.RequiredError('macAppId','Required parameter requestParameters.macAppId was null or undefined when calling updateApplication1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macAppId !== undefined && requestParameters.macAppId !== null) {
            headerParameters['Mac-App-Id'] = String(requestParameters.macAppId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}/{action}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))).replace(`{${"action"}}`, encodeURIComponent(String(requestParameters.action))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchApplicationRequestDtoToJSON(requestParameters.patchApplicationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseDtoFromJSON(jsonValue));
    }

    /**
     * This operation allows partial updates to an application\'s data. Only the fields provided in the request will be updated, leaving the rest of the application\'s data unchanged.
     * Updates only necessary field for Application
     */
    async updateApplication1(patchApplicationRequestDto: PatchApplicationRequestDto, appId: string, action: string, macAppId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationResponseDto> {
        const response = await this.updateApplication1Raw({ patchApplicationRequestDto: patchApplicationRequestDto, appId: appId, action: action, macAppId: macAppId }, initOverrides);
        return await response.value();
    }

    /**
     * Approve or Reject a Platform change request for an application.
     * Approve or Reject a Platform change request for an application
     */
    async updateApplicationPlatformRaw(requestParameters: UpdateApplicationPlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateAppPlatformRequestDto === null || requestParameters.updateAppPlatformRequestDto === undefined) {
            throw new runtime.RequiredError('updateAppPlatformRequestDto','Required parameter requestParameters.updateAppPlatformRequestDto was null or undefined when calling updateApplicationPlatform.');
        }

        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling updateApplicationPlatform.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling updateApplicationPlatform.');
        }

        if (requestParameters.macAppId === null || requestParameters.macAppId === undefined) {
            throw new runtime.RequiredError('macAppId','Required parameter requestParameters.macAppId was null or undefined when calling updateApplicationPlatform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macAppId !== undefined && requestParameters.macAppId !== null) {
            headerParameters['Mac-App-Id'] = String(requestParameters.macAppId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}/approval/{requestId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))).replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAppPlatformRequestDtoToJSON(requestParameters.updateAppPlatformRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve or Reject a Platform change request for an application.
     * Approve or Reject a Platform change request for an application
     */
    async updateApplicationPlatform(updateAppPlatformRequestDto: UpdateAppPlatformRequestDto, appId: string, requestId: string, macAppId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateApplicationPlatformRaw({ updateAppPlatformRequestDto: updateAppPlatformRequestDto, appId: appId, requestId: requestId, macAppId: macAppId }, initOverrides);
    }

    /**
     * his operation allows partial updates to an application\'s data. Only the fields provided in the request will be updated, leaving the rest of the application\'s data unchanged.
     * Updates only necessary field for Application
     */
    async updateApplicationsRaw(requestParameters: UpdateApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationResponseDto>> {
        if (requestParameters.patchApplicationRequestDto === null || requestParameters.patchApplicationRequestDto === undefined) {
            throw new runtime.RequiredError('patchApplicationRequestDto','Required parameter requestParameters.patchApplicationRequestDto was null or undefined when calling updateApplications.');
        }

        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling updateApplications.');
        }

        if (requestParameters.macAppId === null || requestParameters.macAppId === undefined) {
            throw new runtime.RequiredError('macAppId','Required parameter requestParameters.macAppId was null or undefined when calling updateApplications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macAppId !== undefined && requestParameters.macAppId !== null) {
            headerParameters['Mac-App-Id'] = String(requestParameters.macAppId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchApplicationRequestDtoToJSON(requestParameters.patchApplicationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseDtoFromJSON(jsonValue));
    }

    /**
     * his operation allows partial updates to an application\'s data. Only the fields provided in the request will be updated, leaving the rest of the application\'s data unchanged.
     * Updates only necessary field for Application
     */
    async updateApplications(patchApplicationRequestDto: PatchApplicationRequestDto, appId: string, macAppId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationResponseDto> {
        const response = await this.updateApplicationsRaw({ patchApplicationRequestDto: patchApplicationRequestDto, appId: appId, macAppId: macAppId }, initOverrides);
        return await response.value();
    }

    /**
     * Update platform an platform
     * Update Platform
     */
    async updatePlatformRaw(requestParameters: UpdatePlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlatformResponseDto>> {
        if (requestParameters.updatePlatformRequestDto === null || requestParameters.updatePlatformRequestDto === undefined) {
            throw new runtime.RequiredError('updatePlatformRequestDto','Required parameter requestParameters.updatePlatformRequestDto was null or undefined when calling updatePlatform.');
        }

        if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
            throw new runtime.RequiredError('platformId','Required parameter requestParameters.platformId was null or undefined when calling updatePlatform.');
        }

        if (requestParameters.macPlatformId === null || requestParameters.macPlatformId === undefined) {
            throw new runtime.RequiredError('macPlatformId','Required parameter requestParameters.macPlatformId was null or undefined when calling updatePlatform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macPlatformId !== undefined && requestParameters.macPlatformId !== null) {
            headerParameters['Mac-Platform-Id'] = String(requestParameters.macPlatformId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/platforms/{platformId}`.replace(`{${"platformId"}}`, encodeURIComponent(String(requestParameters.platformId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePlatformRequestDtoToJSON(requestParameters.updatePlatformRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlatformResponseDtoFromJSON(jsonValue));
    }

    /**
     * Update platform an platform
     * Update Platform
     */
    async updatePlatform(updatePlatformRequestDto: UpdatePlatformRequestDto, platformId: string, macPlatformId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlatformResponseDto> {
        const response = await this.updatePlatformRaw({ updatePlatformRequestDto: updatePlatformRequestDto, platformId: platformId, macPlatformId: macPlatformId }, initOverrides);
        return await response.value();
    }

    /**
     * Update portfolio an portfolio
     * Update Portfolio
     */
    async updatePortfolioRaw(requestParameters: UpdatePortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioResponseDto>> {
        if (requestParameters.updatePortfolioRequestDto === null || requestParameters.updatePortfolioRequestDto === undefined) {
            throw new runtime.RequiredError('updatePortfolioRequestDto','Required parameter requestParameters.updatePortfolioRequestDto was null or undefined when calling updatePortfolio.');
        }

        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling updatePortfolio.');
        }

        if (requestParameters.macPortfolioId === null || requestParameters.macPortfolioId === undefined) {
            throw new runtime.RequiredError('macPortfolioId','Required parameter requestParameters.macPortfolioId was null or undefined when calling updatePortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.macPortfolioId !== undefined && requestParameters.macPortfolioId !== null) {
            headerParameters['Mac-Portfolio-Id'] = String(requestParameters.macPortfolioId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portfolios/{portfolioId}`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePortfolioRequestDtoToJSON(requestParameters.updatePortfolioRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioResponseDtoFromJSON(jsonValue));
    }

    /**
     * Update portfolio an portfolio
     * Update Portfolio
     */
    async updatePortfolio(updatePortfolioRequestDto: UpdatePortfolioRequestDto, portfolioId: string, macPortfolioId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioResponseDto> {
        const response = await this.updatePortfolioRaw({ updatePortfolioRequestDto: updatePortfolioRequestDto, portfolioId: portfolioId, macPortfolioId: macPortfolioId }, initOverrides);
        return await response.value();
    }

}
