import { createContext, ReactNode } from 'react';
import { isProblemDetail } from '@/helpers/errors';

export const ErrorContext = createContext<{
  error: unknown;
  setError: (e?: unknown) => void;
  show: boolean;
  addFieldNames: (names: string[]) => void;
  removeFieldNames: (names: string[]) => void;
}>({
  error: undefined,
  setError: () => {
    return;
  },
  show: false,
  addFieldNames: () => {
    return;
  },
  removeFieldNames: () => {
    return;
  },
});

interface ErrorProviderProps {
  children: ReactNode;
}

const ErrorProvider = ({ children }: ErrorProviderProps) => {
  const [error, setError] = useState<unknown>();
  const [show, setShow] = useState(false);
  const fieldNames = useRef(new Set());

  const handleAddFieldNames = useCallback((names: string[]) => {
    names.forEach((name) => fieldNames.current.add(name));
  }, []);
  const handleRemoveFieldNames = useCallback((names: string[]) => {
    names.forEach((name) => fieldNames.current.delete(name));
  }, []);

  const handleSetError = useMemo(
    () => (e?: unknown) => {
      setError(e);

      if (isProblemDetail(e) && fieldNames.current.size > 0) {
        const fieldName = e.errors?.[0].field ?? '';

        if (fieldNames.current.has(fieldName)) {
          setShow(false);
          return;
        }
      }

      setShow(true);
    },
    [],
  );

  const provided = useMemo(() => {
    return {
      error,
      setError: handleSetError,
      addFieldNames: handleAddFieldNames,
      removeFieldNames: handleRemoveFieldNames,
      show,
    };
  }, [
    error,
    handleSetError,
    show,
    handleAddFieldNames,
    handleRemoveFieldNames,
  ]);

  return (
    <ErrorContext.Provider value={provided}>{children}</ErrorContext.Provider>
  );
};

export default ErrorProvider;
