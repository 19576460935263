import { SelectOption } from '@/interfaces/fields';
import { UseFormRegisterReturn } from 'react-hook-form';

export const handleNumberEmptyString = (v: number) =>
  isNaN(v) ? undefined : v;

export const isHttp = (enabled: boolean, type: SelectOption[]) => {
  return enabled && type[0] && type[0].value === 'HTTP';
};

export const isExec = (enabled: boolean, type: SelectOption[]) => {
  return enabled && type[0] && type[0].value === 'EXEC';
};

export const isTcp = (enabled: boolean, type: SelectOption[]) => {
  return enabled && type[0] && type[0].value === 'TCP';
};

export const getValue = (path: string, map: object) => {
  const value = path
    .split('.')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .reduce((result: any, key) => result[key] ?? '', map);
  return value.toString();
};

export const parseTemplateString = (template: string, map: object) => {
  if (!template) return;
  return template.replace(/\{\{.+?}}/g, (match) => {
    const path = match.substring(2, match.length - 2).trim();
    return getValue(path, map);
  });
};

export const convertReactHookFormToMcInput = (
  registerProps: UseFormRegisterReturn<string>,
) => {
  const { onBlur, onChange, ...rest } = registerProps;
  return { ...rest, input: onChange, blur: onBlur };
};
