import Header from '@/components/Header/Header';
import Main from '@/components/Main/Main';
import Footer from '@/components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { ReactNode, Suspense } from 'react';
import ErrorNotification from '@/components/ErrorNotification/ErrorNotification';
import useError from '@/hooks/useError';
import NotFound from '@/pages/NotFound/NotFound';
import SkipContent from '@/components/SkipContent/SkipContent';
import { StickyHeader } from '@/components/StickyHeader/StickyHeader';
import { AnnouncementNotificationProvider } from '@/providers/AnnouncementNotificationProvider';
// Loading this component async, as Md triggers error
const PipelineStatus = lazy(
  () => import('@/components/PipelineStatus/PipelineStatus'),
);

interface DefaultLayoutProps {
  children?: ReactNode;
}

function DefaultLayout({ children }: DefaultLayoutProps) {
  const location = useLocation();
  const { setError } = useError();
  const [notFound, setNotFound] = useNotFound();

  useEffect(() => {
    setError();
    setNotFound(false);
  }, [location, setError, setNotFound]);

  return (
    <Suspense>
      <AnnouncementNotificationProvider>
        <SkipContent />
        <StickyHeader>
          <Header />
          <PipelineStatus />
          <Breadcrumbs />
        </StickyHeader>
        <Main>
          {!notFound && (
            <>
              <ErrorNotification />

              <Outlet />
              {children}
            </>
          )}
          {notFound && <NotFound />}
        </Main>
        <Footer />
      </AnnouncementNotificationProvider>
    </Suspense>
  );
}

export default DefaultLayout;
