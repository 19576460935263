import classes from './ErrorBoundary.module.css';

const ErrorBoundary = () => {
  return (
    <div className={classes.cont}>
      <h1 className={`mds-headline--large`}>Looks like something went wrong</h1>
      <p>
        You can try reloading the page. If the issue persists, try{' '}
        <a href={''}>contacting</a> the MDP maintainers.
      </p>
    </div>
  );
};

export default ErrorBoundary;
