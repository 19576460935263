import useSWR from 'swr';
import { DefaultApi } from '@@/generated/openapi';
import { CustomSwrHook } from '@/interfaces/swr';
import { normalizeKey } from '@/helpers/swr';

export const useKafkaTopics = ({
  key,
  args,
  config,
  pause,
}: CustomSwrHook<DefaultApi['getTopicsV1']> = {}) => {
  const client = useApiClient();
  const params = useParams();

  const normalizedKey = normalizeKey({
    key,
    pause,
    defaultKey: ['kafka-topics', params?.app, params?.env, true],
  });
  const normalizedArgs = args ?? [params?.app ?? '', params?.env ?? '', true];

  return useSWR(
    normalizedKey,
    () => client.getTopicsV1(...normalizedArgs),
    config,
  );
};
