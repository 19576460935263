import { createContext, ReactNode } from 'react';
import { IdentityV1Dto } from '@@/generated/mtts/openapi';
import { useMsal } from '@azure/msal-react';
import useSwr from 'swr';

export interface UserProviderInterface extends IdentityV1Dto {
  teamRefs: string[];
}

const defaultUser: UserProviderInterface = {
  id: '',
  provider: 'AZURE_AD',
  type: 'USER',
  displayName: '',
  email: '',
  teamRefs: [],
};

export const UserContext = createContext<UserProviderInterface>(defaultUser);

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider = ({ children }: UserProviderProps) => {
  const msal = useMsal();
  const localId = msal.accounts[0]?.localAccountId;
  const client = useMttsApiClient();
  const { data: user } = useSwr(['current-user'], () =>
    client.getIdentity(localId),
  );
  const { data: teamsData } = useSwr(['my-teams'], () =>
    client.getTeams(0, 100, localId),
  );

  const { content: teams } = teamsData || {};

  const result = useMemo(() => {
    if (!user || !teams) {
      return undefined;
    }
    return {
      ...user,
      teamRefs: teams?.map((t) => t.id) ?? [],
    };
  }, [user, teams]);

  return (
    <UserContext.Provider value={result || defaultUser}>
      {result ? children : null}
    </UserContext.Provider>
  );
};

export default UserProvider;
