import { createContext, ReactNode } from 'react';
import { getThemePreference, toggleThemePreference } from '@/helpers/theme';

export const ThemeContext = createContext<{
  theme: string;
  toggleTheme: () => void;
}>({
  theme: 'light',
  toggleTheme: () => {
    return;
  },
});

interface ThemeProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProps) => {
  const [theme, setTheme] = useState(getThemePreference());

  const toggleTheme = useCallback(async () => {
    const newTheme = await toggleThemePreference();
    setTheme(newTheme);
  }, []);

  const handleThemeChange = (activityLog: StorageEvent) => {
    if (activityLog.key === 'theme') {
      setTheme(activityLog.newValue ?? '');
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleThemeChange);

    return () => {
      window.removeEventListener('storage', handleThemeChange);
    };
  });

  const value = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
