import { DefaultApi } from '@@/generated/mtts/openapi';
import { getUsernameByEmailAddress } from './users';

export const getUserTeams = async (client: DefaultApi, teamRefs: string[]) => {
  const promises = teamRefs.map((ref) => client.getTeam(ref));
  return await Promise.all(promises);
};

export const getPersonalTeamName = (email: string) => {
  return getUsernameByEmailAddress(email)
    .replace(/[^a-z0-9]/g, '')
    .slice(0, 25);
};
