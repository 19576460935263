import classNames from 'classnames';
import classes from './FormInputGroup.module.scss';
import { ReactNode } from 'react';

interface FormInputGroupProps {
  children: ReactNode;
  appearance?: 'plain' | 'primary' | 'secondary' | 'tetriary';
  bordered?: boolean;
  width?: 'narrow' | 'normal' | 'full';
}

export const FormInputGroup = ({
  children,
  appearance = 'plain',
  bordered = false,
  width,
}: FormInputGroupProps) => {
  return (
    <div
      className={classNames(classes.wrapper, {
        [classes.primary]: appearance === 'primary',
        [classes.secondary]: appearance === 'secondary',
        [classes.tetriary]: appearance === 'tetriary',
        [classes.plain]: appearance === 'plain',
        [classes.bordered]: bordered,
        [classes[`width-${width || 'full'}`]]: true,
      })}
    >
      {children}
    </div>
  );
};
