import classes from './Breadcrumbs.module.scss';

const paramsToTransform = ['tab', 'env'];

const Breadcrumbs = () => {
  const location = useLocation();
  const params = useParams();

  const paramValueToKey = useMemo(() => {
    return Object.entries(params).reduce(
      (acc: Record<string, string>, value) => {
        const [key, entry] = value;
        if (key && entry) {
          acc[entry] = key;
        }

        return acc;
      },
      {},
    );
  }, [params]);

  // Remove leading and trailing slashes, so we don't have empty entries.
  const breadcrumbs = location.pathname.replace(/^\/(.*)\/*$/, '$1').split('/');

  const getBreadcrumbLink = (index: number) => {
    if (index === 0 && breadcrumbs[0] === 'app') {
      return '/';
    }

    const slicedBreadcrumbs = breadcrumbs.slice(0, index + 1).join('/');

    return `/${slicedBreadcrumbs}`;
  };

  const getBreadcrumbText = (breadcrumb: string) => {
    if (
      paramValueToKey[breadcrumb] &&
      !paramsToTransform.includes(paramValueToKey[breadcrumb])
    ) {
      // Breadcrumb is a param, so don't transform value
      return decodeURIComponent(breadcrumb);
    }

    // Replace dash with space and capitalize each word
    return decodeURIComponent(
      breadcrumb
        .split('-')
        .map((b) =>
          b.substring(0, 1).toLocaleUpperCase().concat(b.substring(1)),
        )
        .join(' '),
    );
  };

  if (breadcrumbs.length === 1) {
    // homepage
    return null;
  }

  return (
    <nav aria-label={'Breadcrumbs'}>
      <ol className={classes.breadcrumbs}>
        {breadcrumbs.map((breadcrumb, index) => {
          if (index === breadcrumbs.length - 1) {
            return (
              <li key={breadcrumb} className={classes.breadcrumb}>
                {getBreadcrumbText(breadcrumb)}
              </li>
            );
          }

          return (
            <li key={index} className={classes.breadcrumb}>
              <Link to={getBreadcrumbLink(index)} className={classes.link}>
                {getBreadcrumbText(breadcrumb)}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
